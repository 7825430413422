import { useConfig } from "hooks/useConfig";
import { useAppDispatch } from "hooks/redux.hooks";
import { initializeConfig } from "state/features/config/configActions";
import { Loader } from "components/Loader/Loader";

export const WithConfig = ({ children }: { children: React.ReactNode }) => {
  const { config } = useConfig();
  const dispatch = useAppDispatch();
  if (config.status === "uninitialized") {
    dispatch(initializeConfig());
  }
  if (config.status === "ready") return children;
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <Loader />
    </div>
  );
};
