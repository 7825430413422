const iconUrl = {
  Facebook: "icon/facebook.svg",
  FacebookDark: "icon/facebookDark.svg",
  Twitter: "icon/twitter.svg",
  Instagram: "icon/instagram.svg",
  InstagramDark: "icon/instagramDark.svg",
  Pinterest: "icon/pinterest.svg",
  PinterestDark: "icon/pinterestDark.svg",
  Calendar: "icon/calendar.svg",
  Linkedin: "icon/linkedin.svg",
  LinkedinDark: "icon/linkedinDark.svg",
  filter: "icon/filter.svg",
  Check: "icon/check.svg",
  Google: "icon/google.svg",
  MyElla: "icon/myElla.svg",
  EllaSaysHello: "icon/ellaSaysHello.svg",
  EllaConfused: "icon/ellaConfused.svg",
  EllaHasAnIdea: "icon/ellaHasAnIdea.svg",
  EllaIsCelebrating: "icon/ellaIsCelebrating.svg",
  Loader: "icon/loader.svg",
  CircleGreenGradientArrowLeft: "icon/circleGreenGradientArrowLeft.svg",
  CloseGreen: "icon/closeGreen.svg",
  FacebookCircle: "icon/facebookCircle.svg",
  Trash: "icon/trash.svg",
  EllaIsWorking: "icon/ellaIsWorking.svg",
  Connect: "icon/connect.svg",
  ConnectWhite: "icon/connectWhite.svg",
  ArrowDark: "icon/arrowDark.svg",
  Profile: "icon/profile.svg",
  Logout: "icon/logout.svg",
  Settings: "icon/settings.svg",
  Pencil: "icon/pencil.svg",
  Question: "icon/question.svg",
  PencilRounded: "icon/pencilRounded.svg",
  TickCircle: "icon/tickCircle.svg",
  Tick: "icon/tick.svg",
  TickGrey: "icon/tickGrey.svg",
  EllaWithMegaphone: "icon/ellaWithMegaphone.svg",
  CloseCircle: "icon/closeCircle.svg",
  Add: "icon/add.svg",
  SearchMinor: "icon/searchMinor.svg",
  More: "icon/more.svg",
  InfoRed: "icon/infoRed.svg",
  Israel: "icon/israel.svg",
  Usa: "icon/usa.svg",
  Italy: "icon/italy.svg",
  Germany: "icon/germany.svg",
  France: "icon/france.svg",
  Spain: "icon/spain.svg",
  India: "icon/india.svg",
  Lock: "icon/lock.svg",
  Close: "icon/close.svg",
  Watch: "icon/watch.svg",
  PostsInMaking: "icon/postsInMaking.svg",
  TrialEnded: "icon/trialEnded.svg",
  TrialStart: "icon/trialStart.svg",
  Back: "icon/back.svg",
  SocialMediaMarketingRocket: "icon/socialMediaMarketingRocket.svg",
  Info: "icon/info.svg",
  checkWhite: "icon/checkWhite.svg",
  TickBlack: "icon/tickBlack.svg",
  XCircleFilled: "icon/xCircleFilled.svg",
  TickCircleFilled: "icon/tickCircleFilled.svg",
};
type IconKey = keyof typeof iconUrl;

export { iconUrl };

export type { IconKey };
