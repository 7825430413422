import { ErrorBoundary } from "react-error-boundary";
import { ErrorInfo } from "react";
import { HeadTitle } from "components/TextElement/TextElement";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface FallbackRenderProps {
  error: Error;
}

function fallbackRender({ error }: FallbackRenderProps) {
  return (
    <main
      role="alert"
      className="bg-primary h-screen w-screen text-platform justify-center items-center flex flex-col gap-y-2"
    >
      <HeadTitle element="Large"> Something went wrong:</HeadTitle>
      <pre className="bg-navbar px-3 py-2 rounded-[8px]">{error.message}</pre>
    </main>
  );
}

const logError = (error: Error, info: ErrorInfo) => {
  console.error(error, info);
};
export const CustomErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  return (
    <ErrorBoundary fallbackRender={fallbackRender} onError={logError}>
      {children}
    </ErrorBoundary>
  );
};
