import { useAuth } from "hooks/useAuth";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux.hooks";
import { setRedirectToPage } from "state/features/auth/authActions";
import tw from "tailwind-styled-components";
import { BodyText } from "components/TextElement/TextElement";
import { Button } from "components/Button/Button";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useVerifyIdTokenQuery } from "api/services/auth/authService";
import { skipToken } from "@reduxjs/toolkit/query";
import { identifyUser, trackRedirectToLoginPage } from "state/features/analytics/analyticsActions";

export function WithAuth() {
  const {
    auth: { expiresAt, idToken },
  } = useAuth();
  const [expired, setExpired] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const identified = useRef<boolean>(false);
  const initialIsExpired = useRef<boolean | null>(
    expiresAt ? expiresAt < DateTime.now().toUnixInteger() - 1 : null
  );
  const firedExpirationTimeout = useRef<boolean>(false);
  const { loginUrl } = useAppSelector((state) => state.config);
  if (!loginUrl) throw new Error("login url is not defined");

  const verifyIdTokenQuery = useVerifyIdTokenQuery(idToken ? { idToken } : skipToken, {
    skip: !!(!idToken || initialIsExpired.current),
  });

  const refreshIdToken = useCallback(() => {
    dispatch(setRedirectToPage(pathname));
    dispatch(trackRedirectToLoginPage());
    window.location.href = loginUrl;
  }, [dispatch, loginUrl, pathname]);

  useEffect(() => {
    // redirect to login page if idToken is missing or user is unverified
    if (!idToken) {
      navigate("oauth/login");
      return;
    }
    if (verifyIdTokenQuery.isError) {
      navigate("oauth/login");
      return;
    }
  }, [idToken, navigate, verifyIdTokenQuery.isError]);

  useEffect(() => {
    // identify user, if authenticated
    if (!idToken) return;
    if (identified.current) return;
    if (!verifyIdTokenQuery.isSuccess) return;
    const userSubToken = verifyIdTokenQuery.data?.auth.sub;
    if (!userSubToken) return;
    identified.current = true;
    dispatch(identifyUser({ userIdentifier: userSubToken }));
  }, [dispatch, idToken, verifyIdTokenQuery.data?.auth.sub, verifyIdTokenQuery.isSuccess]);

  useEffect(() => {
    // refresh initially expired token
    if (initialIsExpired.current !== true) return;
    refreshIdToken();
  }, [refreshIdToken]);

  useEffect(() => {
    // fire timeout to set expired to true
    if (initialIsExpired.current) return;
    if (firedExpirationTimeout.current) return;
    firedExpirationTimeout.current = true;
    const timeToExpiration = expiresAt ? expiresAt - DateTime.now().toUnixInteger() - 1 : 0;
    const timeout = setTimeout(() => {
      setExpired(true);
    }, timeToExpiration * 1000);
    return () => clearTimeout(timeout);
  }, [expiresAt, dispatch]);

  return (
    <>
      {expired && !initialIsExpired.current && (
        <ModalPaper>
          <ModalContainer>
            <BodyText element="XLarge" translationKey="sessionExpired:title" />
            <Button onClick={refreshIdToken}>{t("sessionExpired:button")}</Button>
          </ModalContainer>
        </ModalPaper>
      )}
      <Outlet />
    </>
  );
}

const ModalPaper = tw.div`
  fixed
  inset-0
  bg-black
  bg-opacity-50
  flex
  justify-center
  items-center
  z-[100]
  backdrop-filter
  backdrop-blur-[4px]
`;

const ModalContainer = tw.div`
  p-[15px]
  flex
  flex-col
  bg-white
  rounded-[8px]
  basis-[430px]
  gap-y-[20px]
`;
