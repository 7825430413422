import tw from "tailwind-styled-components";
import { Navbar } from "components/NavBar/NavBar";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { Loader } from "components/Loader/Loader";
import "overlayscrollbars/overlayscrollbars.css";
import { OverlayScrollbars } from "overlayscrollbars";

OverlayScrollbars(document.body, {});

const AppLayout = () => {
  return (
    <Container>
      <Navbar key="navbar" />
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </Container>
  );
};

const Container = tw.main`
  grid 
  h-screen
  w-screen
  grid-rows-[55px_1fr]
  bg-primary
`;

export default AppLayout;
