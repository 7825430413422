import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface HistoryState {
  lastVisitedBrandId?: string;
  lastVisitedPath?: string;
}

const initialState: HistoryState = {};

const historySlice = createSlice({
  initialState,
  name: "history",
  reducers: {
    setLastVisitedBrandId: (state, action: PayloadAction<string>) => {
      state.lastVisitedBrandId = action.payload;
    },
    setLastVisitedPath: (state, action: PayloadAction<string>) => {
      state.lastVisitedPath = action.payload;
    },
  },
});

export default historySlice;
