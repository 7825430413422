import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/redux.hooks";
import { setPageView } from "state/features/history/historyActions";

export const usePageViews = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const path = `${location.pathname}${location.hash}${location.search}`;
    dispatch(setPageView(path));
  }, [dispatch, location]);
};
