import { createApi } from "@reduxjs/toolkit/query/react";
import { SubscriptionDetails } from "types/subscription.type";
import { dynamicBaseQuery } from "utils/dynamicBaseQuery";

const subscriptionService = createApi({
  reducerPath: "subscriptionService",
  tagTypes: ["subscription"],
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getSubscriptionDetails: builder.query<boolean, string>({
      query: (brandId: string) => ({
        url: `brand/${brandId}/get-subscription-details`,
        retryOptions: {
          maxRetries: 2,
        },
      }),
      providesTags: ["subscription"],
      transformResponse: (response: SubscriptionDetails) => response.subscription_enabled,
    }),
  }),
});

export const { useGetSubscriptionDetailsQuery } = subscriptionService;

export { subscriptionService };
