// {
//   "posting_date": "2023-11-28 11:00:00",
//   "actual_posting_date_in_unix": 1701162000,
//   "platform": "facebook",
//   "approved_to_post": true,
//   "successfully_posted": null,
//   "locked_by_scheduler": true
// },

import { ContentCalendarSchedule } from "types/contentCalendarSchedule.type";
import { replaceAllObjKeys } from "utils/trasnformResponseFromApi/replaceAllObjKeys";

const transformContentCalendarScheduleKeys: Record<string, string> = {
  posting_date: "postingDate",
  actual_posting_date_in_unix: "actualPostingDateInUnix",
  platform: "platform",
  approved_to_post: "approvedToPost",
  successfully_posted: "successfullyPosted",
  locked_by_scheduler: "lockedByScheduler",
  post_id: "postId",
  overrides: "overrides",
  image_url: "imageUrl",
};

function fromResponse(
  response: Record<keyof typeof transformContentCalendarScheduleKeys, unknown>
): ContentCalendarSchedule {
  return replaceAllObjKeys<
    ContentCalendarSchedule,
    Record<keyof typeof transformContentCalendarScheduleKeys, unknown>
  >(response, (key) => {
    const newKey = transformContentCalendarScheduleKeys[key];
    return newKey || key;
  });
}

function toResponse(
  contentCalendarSchedule: Partial<ContentCalendarSchedule>
): Record<string, unknown> {
  return replaceAllObjKeys<Record<string, unknown>, Partial<ContentCalendarSchedule>>(
    contentCalendarSchedule,
    (key) => {
      const newKey = Object.keys(transformContentCalendarScheduleKeys).find(
        (k) => transformContentCalendarScheduleKeys[k] === key
      );
      return newKey || key;
    }
  );
}

export default { fromResponse, toResponse };
