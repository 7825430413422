import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { RootReducer } from "types/state.type";
import { authMiddleware } from "state/features/auth/authMiddleware";
import { apisMiddleware, apisReducer } from "state/reducers/apis.reducers";
import { sliceReducer } from "state/reducers/slices.reducers";
import { configMiddleware } from "state/features/config/configMiddleware";
import { historyMiddleware } from "state/features/history/historyMiddleware";
import { SLICES } from "types/slices.type";
import { analyticsMiddleware } from "state/features/analytics/analyticsMiddleware";
import { setupListeners } from "@reduxjs/toolkit/query";
import { brandNotificationsMiddleware } from "state/features/brandNotifications/brandNotificationsMiddleware";
import { userNotificationsMiddleware } from "state/features/userNotifications/userNotificationsMiddleware";

const persistSliceConfig = {
  key: "v0.4.8", //TODO : get this from env
  storage,
  whitelist: [SLICES.HISTORY, SLICES.AUTH, SLICES.BRAND_NOTIFICATIONS, SLICES.USER_NOTIFICATIONS],
};

const rootReducer = combineReducers({
  ...sliceReducer,
  ...apisReducer,
});

const store = configureStore({
  reducer: persistReducer<RootReducer>(persistSliceConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(apisMiddleware)
      .concat(authMiddleware)
      .concat(historyMiddleware)
      .concat(configMiddleware)
      .concat(brandNotificationsMiddleware)
      .concat(analyticsMiddleware)
      .concat(userNotificationsMiddleware),
});

setupListeners(store.dispatch);
const persistor = persistStore(store);
export { persistor, store, rootReducer };
