import { Middleware } from "@reduxjs/toolkit";
import {
  removeUserNotification,
  setUserNotifications,
  upsertUserNotification,
} from "state/features/userNotifications/userNotificationsActions";
import { RootState } from "types/state.type";

const upsertUserNotificationHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (upsertUserNotification.match(action)) {
      const state: RootState = getState();
      const { notification, userSub } = action.payload;
      const exists = state.userNotifications[userSub]?.find((n) => n === notification);
      if (!exists) {
        const newNotifications = [...(state.userNotifications[userSub] ?? []), notification];
        dispatch(setUserNotifications({ userSub, notifications: newNotifications }));
      }
    }
  };

export const removeUserNotificationHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (removeUserNotification.match(action)) {
      const state: RootState = getState();
      const { notification, userSub } = action.payload;
      const exists = state.userNotifications[userSub]?.find((n) => n === notification);
      if (exists) {
        const newNotifications = state.userNotifications[userSub]?.filter(
          (n) => n !== notification
        );
        dispatch(setUserNotifications({ userSub, notifications: newNotifications }));
      }
    }
  };

export const userNotificationsMiddleware = [
  upsertUserNotificationHandler,
  removeUserNotificationHandler,
];
