import brandNotificationsSlice, {
  BrandNotification,
} from "state/features/brandNotifications/brandNotificationsSlice";
import { createAction } from "@reduxjs/toolkit";

export const { setBrandNotification } = brandNotificationsSlice.actions;

export const upsertBrandNotification = createAction<
  {
    brandId: string;
    notification: BrandNotification;
  },
  "brandNotifications/upsertBrandNotification"
>("brandNotifications/upsertBrandNotification");

export const removeBrandNotification = createAction<
  {
    brandId: string;
    notification: BrandNotification;
  },
  "brandNotifications/removeBrandNotification"
>("brandNotifications/removeBrandNotification");
