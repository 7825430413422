import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "utils/dynamicBaseQuery";
import transformBrand from "utils/trasnformResponseFromApi/transformBrand";
import { BrandScrapedHomepageDetails } from "types/brandScrapedHompageDetails.type";
import { Brand } from "types/brand.type";
import transformBrandScrapedHomepageDetails from "utils/trasnformResponseFromApi/transformBrandScrapedHomepageDetails";
import {
  trackApprovedScrapedHomepageDetailsEnd,
  trackApprovedScrapedHomepageDetailsStart,
  trackBrandAddedEnd,
  trackBrandAddedStart,
  trackBrandIconPresignEnd,
  trackBrandIconPresignStart,
  trackBrandIconUploadedEnd,
  trackBrandUpdatedEnd,
  trackBrandUpdatedStart,
} from "state/features/analytics/analyticsActions";
import { RootState } from "types/state.type";
import { contentService } from "api/services/content/content.service";
import { setLastTimeDismissed } from "pages/brands/[brandId]/weekly-calendar/hooks/useAutopilotPush";

const brandService = createApi({
  reducerPath: "brandService",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["BrandScrapedHomepageDetails", "Brand", "userBrands", "ContentCalendarSchedule"],
  endpoints: (builder) => ({
    getUserLatestBrand: builder.query<Brand | undefined, void>({
      query: () => "brand?limit=1",
      transformResponse: (response) => {
        const { brands } = response as { brands: Record<string, unknown>[] };
        if (!brands || !brands.length) return undefined;
        const latestBrand = transformBrand.fromResponse(brands.at(-1)!);
        if (!latestBrand.brandId) {
          return undefined;
        }
        return latestBrand;
      },
    }),
    getUserBrands: builder.query<
      Pick<Brand, "brandId" | "brandName" | "brandSettings">[],
      { limit?: number; offset?: number; brandNameIncludes?: string }
    >({
      providesTags: ["userBrands"],
      queryFn: async ({ limit, offset, brandNameIncludes }, api, _arg, fetchWithBQ) => {
        const searchParams = new URLSearchParams();
        if (!brandNameIncludes) {
          searchParams.append("limit", limit?.toString() ?? "");
          searchParams.append("offset", offset?.toString() ?? "");
        } else {
          searchParams.append("limit", "50");
        }
        try {
          const { data } = (await fetchWithBQ({
            method: "GET",
            url: `brand?${searchParams.toString()}`,
            retryOptions: {
              maxRetries: 2,
            },
          })) as { data: { brands: Record<string, unknown>[] } };
          if (!("brands" in data)) {
            return { error: { data: "Failed to get brands", status: 500 } };
          }
          const transformed = data.brands.map((brand) => transformBrand.fromResponse(brand));
          if (brandNameIncludes) {
            let data = transformed.filter((brand) =>
              brand.brandName.toLowerCase().includes(brandNameIncludes.toLowerCase())
            );
            if (offset) {
              data = data.slice(offset);
            }
            if (limit) {
              data = data.slice(0, limit);
            }
            return {
              data,
            };
          }
          return {
            data: transformed,
          };
        } catch (error) {
          return { error: { data: "Failed to get brands", status: 500 } };
        }
      },
    }),
    getBrandScrapedHomepageDetails: builder.query<
      Partial<BrandScrapedHomepageDetails> | undefined,
      { brandId: string }
    >({
      providesTags: ["BrandScrapedHomepageDetails"],
      queryFn: async ({ brandId }, api, _arg, fetchWithBQ) => {
        try {
          const res = (await fetchWithBQ({
            method: "GET",
            url: `mini-scrape/${brandId}`,
            retryOptions: {
              maxRetries: 2,
            },
          })) as { data: Record<string, unknown> };
          if ("error" in res || !("data" in res)) {
            return {
              data: undefined,
            };
          }
          return {
            data: transformBrandScrapedHomepageDetails.fromResponse(res.data),
          };
        } catch (error) {
          return { error: { data: "Failed to get brand", status: 500 } };
        }
      },
      extraOptions: {
        maxRetries: 0,
      },
    }),
    createBrand: builder.mutation<
      Brand,
      { brandName: string; socialMediaAutopilot: boolean; postingTz: string }
    >({
      invalidatesTags: ["Brand", "userBrands"],
      queryFn: async ({ brandName, socialMediaAutopilot, postingTz }, api, _arg, fetchWithBQ) => {
        try {
          const { data } = (await fetchWithBQ({
            method: "POST",
            body: {
              brand_name: brandName,
              brand_settings: {
                social_media_autopilot: socialMediaAutopilot,
                posting_tz: postingTz,
              },
            },
            url: "brand",
            retryOptions: {
              maxRetries: 2,
            },
          })) as { data: { brand: Record<string, unknown> } };
          if (!("brand" in data)) {
            return { error: { data: "Failed to create brand", status: 500 } };
          }
          const transformed = transformBrand.fromResponse(data.brand);
          return {
            data: transformed,
          };
        } catch (error) {
          return { error: { data: "Failed to create brand", status: 500 } };
        }
      },
      onQueryStarted: async ({ brandName }, { dispatch, getState, queryFulfilled }) => {
        const state = getState() as RootState;
        const { organizationId } = state.auth;
        dispatch(
          trackBrandAddedStart({
            brandName: brandName!,
          })
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            trackBrandAddedEnd({
              status: "resolved",
              brandName: brandName!,
              brandId: data?.brandId,
              organizationId: organizationId!,
            })
          );
        } catch (e) {
          dispatch(
            trackBrandAddedEnd({
              status: "rejected",
              brandName: brandName!,
              organizationId: organizationId!,
            })
          );
          return;
        }
      },
    }),
    storeBrandScrapedHomepageDetails: builder.mutation<
      Partial<BrandScrapedHomepageDetails>,
      { brandId: string; data: Partial<BrandScrapedHomepageDetails> }
    >({
      invalidatesTags: ["BrandScrapedHomepageDetails", "Brand"],
      queryFn: async ({ brandId, data }, api, _arg, fetchWithBQ) => {
        try {
          const transformed = transformBrandScrapedHomepageDetails.toResponse(data);
          const { data: res } = (await fetchWithBQ({
            method: "PUT",
            body: transformed,
            url: `mini-scrape/${brandId}`,
            retryOptions: {
              maxRetries: 2,
            },
          })) as { data: Record<string, unknown> };
          if (!("stored" in res)) {
            return { error: { data: "Failed to store brand", status: 500 } };
          }
          return {
            data: transformBrandScrapedHomepageDetails.fromResponse(res),
          };
        } catch (error) {
          return { error: { data: `Failed to store brand ${error}`, status: 500 } };
        }
      },
      onQueryStarted: async ({ brandId, data }, { dispatch, getState, queryFulfilled }) => {
        const state = getState() as RootState;
        const { organizationId } = state.auth;
        dispatch(
          trackApprovedScrapedHomepageDetailsStart({
            brandId: brandId!,
            homepageUrl: data["original_url" as keyof BrandScrapedHomepageDetails]! as string,
            organizationId: organizationId!,
          })
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            trackApprovedScrapedHomepageDetailsEnd({
              status: "resolved",
              brandId: brandId!,
              homepageUrl: data["original_url" as keyof BrandScrapedHomepageDetails]! as string,
              organizationId: organizationId!,
            })
          );
        } catch (e) {
          dispatch(
            trackApprovedScrapedHomepageDetailsEnd({
              status: "rejected",
              brandId: brandId!,
              homepageUrl: data.originalUrl!,
              organizationId: organizationId!,
            })
          );
          return;
        }
      },
    }),
    getBrandById: builder.query<Brand | undefined, { brandId: string }>({
      providesTags: ["Brand"],
      queryFn: async ({ brandId }, api, _arg, fetchWithBQ) => {
        try {
          const { data } = (await fetchWithBQ({
            method: "GET",
            url: `brand/${brandId}`,
            retryOptions: {
              maxRetries: 2,
            },
          })) as { data: Record<string, unknown> };
          if (!data) {
            return { error: { data: "Failed to get brand", status: 500 } };
          }
          const transformed = transformBrand.fromResponse(data);
          return {
            data: transformed,
          };
        } catch (error) {
          return { error: { data: "Failed to get brand", status: 500 } };
        }
      },
    }),
    updateBrand: builder.mutation<
      { stored: boolean },
      { brandId: string; data: Partial<Pick<Brand, "brandName" | "brandSettings">> }
    >({
      invalidatesTags: ["Brand", "userBrands", "ContentCalendarSchedule"],
      queryFn: async ({ brandId, data }, api, _arg, fetchWithBQ) => {
        try {
          const transformed = transformBrand.toResponse(data);
          const { data: res } = (await fetchWithBQ({
            method: "PUT",
            body: transformed,
            url: `brand/${brandId}`,
            retryOptions: {
              maxRetries: 2,
            },
          })) as { data: Record<string, unknown> };
          if (!("updated" in res)) {
            return { error: { data: "Failed to update brand", status: 500 } };
          }
          return {
            data: {
              stored: true,
            },
          };
        } catch (error) {
          return { error: { data: `Failed to update brand ${error}`, status: 500 } };
        }
      },
      onCacheEntryAdded: (arg, { dispatch }) => {
        dispatch(contentService.util.invalidateTags(["ContentCalendarSchedule"]));
      },
      onQueryStarted: async ({ brandId, data: updatedBrand }, { dispatch, queryFulfilled }) => {
        dispatch(
          trackBrandUpdatedStart({
            brandId: brandId!,
            updatedBrand: updatedBrand!,
          })
        );
        try {
          await queryFulfilled;
          dispatch(
            trackBrandUpdatedEnd({
              status: "resolved",
              brandId: brandId!,
              updatedBrand: updatedBrand!,
            })
          );
          const isAutopilotDisabled = !updatedBrand.brandSettings?.socialMediaAutopilot;
          if (isAutopilotDisabled) {
            setLastTimeDismissed(brandId!);
          }
        } catch (e) {
          dispatch(
            trackBrandUpdatedEnd({
              status: "rejected",
              brandId: brandId!,
              updatedBrand: updatedBrand!,
            })
          );
          return;
        }
      },
    }),
    presignAndUploadBrandIcon: builder.mutation<
      { brandIconUrl: string },
      { brandId: string; fileType: string; file: Blob }
    >({
      queryFn: async ({ brandId, fileType, file }, api, _arg, fetchWithBQ) => {
        let presignedUrl: string;
        let brandIconUrl: string;
        let presignedPostFields: Record<string, string>;

        try {
          api.dispatch(
            trackBrandIconPresignStart({
              brandId: brandId!,
              fileType,
            })
          );
          const { data } = await fetchWithBQ({
            method: "POST",
            body: {
              file_type: fileType,
            },
            url: `brand/${brandId}/prepare-icon-upload`,
            retryOptions: {
              maxRetries: 2,
            },
          });
          const { presigned_url, brand_icon_url, fields } = data as {
            presigned_url: string;
            brand_icon_url: string;
            fields: Record<string, string>;
          };
          if (!presigned_url || !brand_icon_url || !fields) {
            api.dispatch(
              trackBrandIconPresignEnd({
                brandId: brandId!,
                fileType,
                status: "rejected",
              })
            );
            return { error: { data: "Failed to prepare brand icon upload", status: 500 } };
          }
          presignedUrl = presigned_url;
          brandIconUrl = brand_icon_url;
          presignedPostFields = fields;
          api.dispatch(
            trackBrandIconPresignEnd({
              brandId: brandId!,
              fileType,
              status: "resolved",
            })
          );
        } catch (error) {
          api.dispatch(
            trackBrandIconPresignEnd({
              brandId: brandId!,
              fileType,
              status: "rejected",
            })
          );
          return {
            error: {
              data: `Failed to prepare brand icon upload ${JSON.stringify(error)}`,
              status: 500,
            },
          };
        }

        const formData = new FormData();
        Object.entries({ ...presignedPostFields }).forEach(([field, value]) => {
          formData.append(field, value);
        });
        formData.append("file", file);
        try {
          const res = await fetch(presignedUrl, {
            method: "POST",
            body: formData,
          });
          if (!res.ok) {
            api.dispatch(
              trackBrandIconUploadedEnd({
                brandId: brandId!,
                presignedUrl,
                status: "rejected",
              })
            );
            return { error: { data: "Failed to upload brand icon", status: 500 } };
          }
          api.dispatch(
            trackBrandIconUploadedEnd({
              brandId: brandId!,
              presignedUrl,
              status: "resolved",
            })
          );
          return {
            data: {
              brandIconUrl,
            },
          };
        } catch (error) {
          api.dispatch(
            trackBrandIconUploadedEnd({
              brandId: brandId!,
              presignedUrl,
              status: "rejected",
            })
          );
          return {
            error: { data: `Failed to upload brand icon ${JSON.stringify(error)}`, status: 500 },
          };
        }
      },
    }),
  }),
});

export const {
  useGetUserLatestBrandQuery,
  useGetBrandScrapedHomepageDetailsQuery,
  useCreateBrandMutation,
  useStoreBrandScrapedHomepageDetailsMutation,
  useGetBrandByIdQuery,
  useGetUserBrandsQuery,
  useUpdateBrandMutation,
  usePresignAndUploadBrandIconMutation,
  useLazyGetBrandByIdQuery,
} = brandService;

export { brandService };
