import { BrandScrapedHomepageDetails } from "types/brandScrapedHompageDetails.type";
import { replaceAllObjKeys } from "utils/trasnformResponseFromApi/replaceAllObjKeys";

const transformBrandScrapedHomePageDetailsKeys: Record<string, keyof BrandScrapedHomepageDetails> =
  {
    original_url: "originalUrl",
    attempted_scraped_url: "attemptedScrapedUrl",
    actual_scraped_url: "actualScrapedUrl",
    canonical_url: "canonicalUrl",
    title: "title",
    description: "description",
    image_url: "imageUrl",
    detected_site_type: "detectedSiteType",
    social_links: "socialLinks",
    detected_language: "detectedLanguage",
  };

function fromResponse(
  response: Record<keyof typeof transformBrandScrapedHomePageDetailsKeys, unknown>
): BrandScrapedHomepageDetails {
  return replaceAllObjKeys<
    BrandScrapedHomepageDetails,
    Record<keyof typeof transformBrandScrapedHomePageDetailsKeys, unknown>
  >(response, (key) => {
    const newKey = transformBrandScrapedHomePageDetailsKeys[key];
    return newKey || key;
  });
}

function toResponse(
  brandScrapedHomepageDetails: Partial<BrandScrapedHomepageDetails>
): Record<keyof typeof transformBrandScrapedHomePageDetailsKeys, unknown> {
  return replaceAllObjKeys(brandScrapedHomepageDetails, (key) => {
    const newKey = Object.keys(transformBrandScrapedHomePageDetailsKeys).find(
      (transformedKey) => transformBrandScrapedHomePageDetailsKeys[transformedKey] === key
    );
    return newKey || key;
  });
}

export default { fromResponse, toResponse };
