import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "utils/dynamicBaseQuery";
import { BrandScrapedHomepageDetails } from "types/brandScrapedHompageDetails.type";
import transformBrandScrapedHomepageDetails from "utils/trasnformResponseFromApi/transformBrandScrapedHomepageDetails";
import {
  trackScrapedHomepageDetailsEnd,
  trackScrapedHomepageDetailsStart,
} from "state/features/analytics/analyticsActions";
import { RootState } from "types/state.type";

const scraperService = createApi({
  reducerPath: "scraperService",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    scrapeOnDemand: builder.query<
      Partial<BrandScrapedHomepageDetails>,
      { homepageUrl: string; brandId?: string }
    >({
      queryFn: async ({ homepageUrl }, _, _extraOptions, fetchWithBQ) => {
        try {
          const { data } = (await fetchWithBQ({
            timeout: 4000,
            params: {
              url: homepageUrl,
            },
            url: "mini-scrape",
            retryOptions: {
              maxRetries: 2,
            },
          })) as { data: Record<string, unknown> };

          if (
            "error" in data ||
            !("original_url" in data) ||
            data.detected_language === "unknown"
          ) {
            return { error: { data: "Invalid url", status: 232 } };
          }
          return {
            data: transformBrandScrapedHomepageDetails.fromResponse(data),
          };
        } catch (error) {
          return { error: { data: "Failed to scrape url", status: 500 } };
        }
      },
      onQueryStarted: async ({ brandId, homepageUrl }, { dispatch, queryFulfilled, getState }) => {
        const state = getState() as RootState;
        const { organizationId } = state.auth;
        dispatch(
          trackScrapedHomepageDetailsStart({
            homepageUrl,
            brandId,
            organizationId: organizationId!,
          })
        );
        try {
          const res = await queryFulfilled;
          dispatch(
            trackScrapedHomepageDetailsEnd({
              homepageUrl,
              status: "resolved",
              organizationId: organizationId!,
              brandId,
              detectedLanguage: res.data.detectedLanguage,
              detectedSiteType: res.data.detectedSiteType,
            })
          );
        } catch (e) {
          dispatch(
            trackScrapedHomepageDetailsEnd({
              homepageUrl,
              status: "rejected",
              organizationId: organizationId!,
            })
          );
        }
      },
    }),
  }),
});
export const { useLazyScrapeOnDemandQuery, useScrapeOnDemandQuery } = scraperService;
export { scraperService };
