import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "utils/dynamicBaseQuery";
import {
  trackLoginIdTokenVerifiedEnd,
  trackLoginIdTokenVerifiedStart,
  trackLogoutEnd,
  trackLogoutStart,
} from "state/features/analytics/analyticsActions";
import { RootState } from "types/state.type";
import { AuthState } from "state/features/auth/authSlice";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { setAuth } from "state/features/auth/authActions";

const authService = createApi({
  reducerPath: "authService",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    verifyIdToken: builder.query<{ auth: AuthState; verified: true }, { idToken: string }>({
      queryFn: async ({ idToken }, { getState }) => {
        const state = getState() as RootState;
        const { cognitoClientId, cognitoUserPoolId } = state.config;
        if (!cognitoClientId || !cognitoUserPoolId) {
          return {
            error: { data: "Cognito client id or user pool id is not defined", status: 500 },
          };
        }
        const verifier = CognitoJwtVerifier.create({
          userPoolId: cognitoUserPoolId,
          clientId: cognitoClientId,
          tokenUse: "id",
        });
        try {
          const decoded = await verifier.verify(idToken);
          const sub = decoded.sub;
          const email = decoded.email;
          const name = decoded.name;
          const exp = decoded.exp;
          const organizationId = decoded["custom:organization_id"];
          const workspaceDomain = decoded["custom:workspace_domain"];
          const auth: AuthState = {
            idToken,
            sub,
            email: email as string,
            name: name as string,
            organizationId: organizationId as string,
            workspaceDomain: workspaceDomain as string,
            expiresAt: exp,
          };
          return { data: { auth, verified: true } };
        } catch (e) {
          return { error: { data: "Failed to verify id token", status: 401 } };
        }
      },
      onQueryStarted: async (arg, { dispatch, getState, queryFulfilled }) => {
        const state = getState() as RootState;
        const { cognitoClientId, cognitoUserPoolId } = state.config;
        dispatch(
          trackLoginIdTokenVerifiedStart({
            cognitoClientId: cognitoClientId!,
            cognitoUserPoolId: cognitoUserPoolId!,
          })
        );
        try {
          const {
            data: { auth },
          } = await queryFulfilled;
          const adminOrganizationIds = state.config.adminOrganizationId;
          const isAdmin = !!(auth.organizationId
            ? adminOrganizationIds?.includes(auth.organizationId)
            : false);
          dispatch(
            setAuth({
              ...auth,
              isAdmin,
            })
          );
          dispatch(trackLoginIdTokenVerifiedEnd({ status: "resolved", isAdmin }));
        } catch (e) {
          dispatch(
            trackLoginIdTokenVerifiedEnd({
              status: "rejected",
              error: JSON.stringify(e),
            })
          );
        }
      },
    }),
    logout: builder.query<boolean, void>({
      query: () => ({
        url: `auth/logout-callback`,
        method: "POST",
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
        const state = getState() as RootState;
        const { sub, organizationId } = state.auth;
        dispatch(trackLogoutStart({ sub: sub!, organizationId: organizationId! }));
        try {
          await queryFulfilled;
          dispatch(
            trackLogoutEnd({ sub: sub!, organizationId: organizationId!, status: "resolved" })
          );
        } catch (e) {
          dispatch(
            trackLogoutEnd({ sub: sub!, organizationId: organizationId!, status: "rejected" })
          );
          return;
        }
      },
    }),
  }),
});

export const { useLogoutQuery, useVerifyIdTokenQuery } = authService;

export { authService };
