import tw from "tailwind-styled-components";
import { BrandIcon } from "components/BrandIcon/BrandIcon";
import { BodyText, SubTitleText } from "components/TextElement/TextElement";
import { NavLink, useParams } from "react-router-dom";
import { useGetBrandByIdQuery } from "api/services/brand/brand.service";
import { useAuth } from "hooks/useAuth";
import { Loader } from "components/Loader/Loader";
import { Tooltip } from "react-tooltip";
import { Icon } from "components/Icon/Icon";
import { useGetBrandIntegrationsQuery } from "api/services/integrations/integrations.service";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LinkButton } from "components/Button/Button";
import { skipToken } from "@reduxjs/toolkit/query";
import { useVerifyIdTokenQuery } from "api/services/auth/authService";

export const BrandMenu = () => {
  const { brandId } = useParams<{ brandId: string }>();
  const { auth } = useAuth();
  const { t } = useTranslation();
  const idTokenVerifyQuery = useVerifyIdTokenQuery(
    auth.idToken ? { idToken: auth.idToken } : skipToken
  );
  const brandQuery = useGetBrandByIdQuery(
    { brandId: brandId! },
    {
      skip: !brandId,
    }
  );
  const brandIntegrationsQuery = useGetBrandIntegrationsQuery(
    {
      brandId: brandId!,
    },
    {
      skip: !brandId,
    }
  );

  const hasIntegrations = useMemo(() => {
    const integrations = Object.values(brandIntegrationsQuery.data?.integrations ?? {}).filter(
      (value) => value
    );
    return integrations.length > 0;
  }, [brandIntegrationsQuery.data]);

  const isLoading = useMemo(() => {
    return brandQuery.isLoading || brandQuery.isUninitialized || brandIntegrationsQuery.isLoading;
  }, [brandQuery.isLoading, brandQuery.isUninitialized, brandIntegrationsQuery.isLoading]);

  //if user is not authenticated, show nothing
  if (idTokenVerifyQuery.isUninitialized) return null;

  //if user is authenticated but not on a brand, show logout button
  if (!brandId) {
    if (!brandQuery.isUninitialized && brandId) return null;
    return (
      <LinkButton to="oauth/logout" className="flex items-center bg-default">
        {t("navbar:logout")}
      </LinkButton>
    );
  }

  if (isLoading) return <Loader size={20} />;

  return (
    <div className="flex gap-[12px] items-center">
      <SubTitleText element="Large" text={brandQuery.data?.brandName} className="text-platform" />
      <BrandMenuBtn
        size={40}
        brandName={brandQuery.data?.brandName ?? ""}
        imageUrl={brandQuery.data?.brandSettings?.iconUrl ?? brandQuery.data?.imageUrl ?? undefined}
        className="cursor-pointer"
        id="brand-menu-anchor"
      />
      <Tooltip
        clickable={true}
        anchorSelect="#brand-menu-anchor"
        openOnClick={true}
        opacity={1}
        place="bottom-end"
        style={{
          backgroundColor: "#191B22",
          borderRadius: "8px",
          overflow: "hidden",
          boxShadow: "0px 0px 50px 0px rgba(0, 0, 0, 0.25)",
          display: "flex",
          padding: 0,
          flexDirection: "column",
          flexShrink: 0,
          zIndex: 100,
          minWidth: "200px",
          maxWidth: "250px",
        }}
      >
        <BrandName element="Large" text={brandQuery.data?.brandName} className="px-[15px]" />
        {brandId && hasIntegrations && (
          <NavLink
            to={`/brands/${brandId}/social-media-weekly-calendar`}
            className={({ isActive }) => NavLinkClassName(isActive)}
          >
            <Icon iconKey="Calendar" width={24} height={24} />
            <BodyText
              element="Large"
              className="whitespace-nowrap text-[14px] font-[400] text-placeholder"
              translationKey="navbar:brandMenu.calendarButton"
            />
          </NavLink>
        )}
        <NavLink
          to={brandId ? `/brands/${brandId}/change` : "/brands/select"}
          className={({ isActive }) => NavLinkClassName(isActive)}
        >
          <Icon iconKey="Profile" />
          <BodyText
            element="Large"
            className="whitespace-nowrap text-[14px] font-[400] text-placeholder"
            translationKey="navbar:brandMenu.brandsList"
          />
        </NavLink>
        {brandId && hasIntegrations && (
          <NavLink
            to={`/brands/${brandId}/settings`}
            className={({ isActive }) => NavLinkClassName(isActive)}
          >
            <Icon
              iconKey="Settings"
              className="self-center justify-self-center"
              width={19}
              height={19}
            />
            <BodyText
              element="Large"
              className="whitespace-nowrap text-[14px] font-[400] text-placeholder"
              translationKey="navbar:brandMenu.settings"
            />
          </NavLink>
        )}
        <NavLink to="oauth/logout" className={({ isActive }) => NavLinkClassName(isActive)}>
          <Icon iconKey="Logout" />
          <BodyText
            element="Large"
            className="whitespace-nowrap text-[14px] font-[400] text-placeholder"
            translationKey="navbar:logout"
          />
        </NavLink>
      </Tooltip>
    </div>
  );
};

const BrandMenuBtn = tw(BrandIcon)`
  relative
`;

const BrandName = tw(SubTitleText)`
  py-[7px]
  px-[15px]
  mb-[10px]
  border-0
  border-b-[0.5px]
  border-platform
  text-ellipsis
  overflow-hidden
`;

const NavLinkClassName = (isActive: boolean) => {
  let className =
    "grid grid-cols-[24px_1fr] gap-[8px] items-center pe-[16px] transition hover:bg-primary px-[10px] py-[7px]";
  if (isActive) className += " bg-primary";
  return className;
};
