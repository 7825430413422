import { Action, Middleware } from "@reduxjs/toolkit";
import {
  baseTrack,
  identifyUser,
  resetUser,
  trackApprovedScrapedHomepageDetailsEnd,
  trackApprovedScrapedHomepageDetailsStart,
  trackBrandAddedEnd,
  trackBrandAddedStart,
  trackBrandCalendarSlotOverridesUpdatedEnd,
  trackBrandCalendarSlotOverridesUpdatedStart,
  trackBrandEdited,
  trackBrandIconPresignEnd,
  trackBrandIconPresignStart,
  trackBrandIconUploadedEnd,
  trackBrandIconUploadedStart,
  trackBrandUpdatedEnd,
  trackBrandUpdatedStart,
  trackChargebeeCheckoutLoaded,
  trackChargebeeCheckoutPageVisited,
  trackChargebeeCheckoutSetHpToken,
  trackChargebeeSelfServePortalLoaded,
  trackChargebeeSelfServePortalPageVisited,
  trackChargebeeSelfServePortalSubscriptionCancelled,
  trackChargebeeSelfServePortalSubscriptionReactivated,
  trackCheckoutCallbackReceived,
  trackClientRouterPathChanged,
  trackContentSlotUpdatedEnd,
  trackContentSlotUpdatedStart,
  trackEditedScrapedHomepageDetails,
  trackJoinWaitingList,
  trackLocaleRequestedEnd,
  trackLocaleRequestedStart,
  trackLoginCallbackEnd,
  trackLoginCallbackStart,
  trackLoginIdTokenVerifiedEnd,
  trackLoginIdTokenVerifiedStart,
  trackLogoutEnd,
  trackLogoutStart,
  trackOauthConnectEnd,
  trackOauthConnectRedirect,
  trackOauthConnectStart,
  trackOauthProviderPostingPageSelectionEnd,
  trackOauthProviderPostingPageSelectionStart,
  trackPostBlurred,
  trackPostImagePresignEnd,
  trackPostImagePresignStart,
  trackPostImageUploadedEnd,
  trackPostImageUploadedStart,
  trackPostingPagesListEnd,
  trackPostingPagesListStart,
  trackPostViewed,
  trackPublishNow,
  trackRedirectToChargebeeCheckout,
  trackRedirectToChargebeeSelfServePortal,
  trackRedirectToLoginPage,
  trackRejectedApiMutation,
  trackRejectedApiQuery,
  trackRequestedEditPostText,
  trackScrapedHomepageDetailsEnd,
  trackScrapedHomepageDetailsStart,
  trackStaticAssetsFontsInitializeEnd,
  trackStaticAssetsFontsInitializeStart,
  trackStaticAssetsLocaleNamespaceLoadingFailed,
  trackWebsiteLanguageUpdated,
} from "state/features/analytics/analyticsActions";
import mixpanel from "mixpanel-browser";
import { RootState } from "types/state.type";
import transformBrand from "utils/trasnformResponseFromApi/transformBrand";
import transformBrandCalendarSlotOverridesUpdatedEvent from "utils/trasnformResponseFromApi/transformBrandCalendarSlotOverridesUpdatedEvent"; //BASE TRACK HANDLER

//BASE TRACK HANDLER
const baseTrackHandler: Middleware = () => (next) => (action) => {
  next(action);
  if (baseTrack.match(action)) {
    const { properties, eventName } = action.payload;
    mixpanel.track(eventName, properties);
    window.dataLayer?.push({
      event: eventName,
      ...properties,
    });
  }
};

//IDENTIFY USER HANDLER
const identifyUserHandler: Middleware = () => (next) => (action) => {
  next(action);
  if (identifyUser.match(action)) {
    const { userIdentifier } = action.payload;
    mixpanel.identify(userIdentifier);
  }
};

const resetUserHandler: Middleware = () => (next) => (action) => {
  next(action);
  if (resetUser.match(action)) {
    mixpanel.reset();
  }
};

//AUTH HANDLERS
const trackRedirectToLoginPageHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackRedirectToLoginPage.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_redirect_to_login_page",
          properties: {
            organization_id: organizationId,
          },
        })
      );
    }
  };

const trackLogoutStartHandler: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackLogoutStart.match(action)) {
      dispatch(
        baseTrack({
          eventName: "client_logout_start",
          properties: {
            sub: action.payload.sub,
            organization_id: action.payload.organizationId,
          },
        })
      );
    }
  };

const trackLogoutEndHandler: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackLogoutEnd.match(action)) {
      dispatch(
        baseTrack({
          eventName: "client_logout_end",
          properties: {
            sub: action.payload.sub,
            organization_id: action.payload.organizationId,
            status: action.payload.status,
          },
        })
      );
    }
  };

//BRAND ADDED HANDLERS
const trackBrandAddedStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackBrandAddedStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_brand_added_start",
          properties: {
            organization_id: organizationId,
            brand_name: action.payload.brandName,
          },
        })
      );
    }
  };
const trackBrandAddedEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackBrandAddedEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_brand_added_end",
          properties: {
            organization_id: organizationId,
            brand_name: action.payload.brandName,
            status: action.payload.status,
            brand_id: action.payload.brandId,
          },
        })
      );
    }
  };

//SCRAPER HANDLERS
const trackScrapedHomepageDetailsStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackScrapedHomepageDetailsStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_scraped_homepage_details_start",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            homepage_url: action.payload.homepageUrl,
          },
        })
      );
    }
  };
const trackScrapedHomepageDetailsEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackScrapedHomepageDetailsEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_scraped_homepage_details_end",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            homepage_url: action.payload.homepageUrl,
            status: action.payload.status,
            detected_site_type: action.payload.detectedSiteType,
            detected_language: action.payload.detectedLanguage,
          },
        })
      );
    }
  };
const trackApprovedScrapedHomepageDetailsStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackApprovedScrapedHomepageDetailsStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_approved_scraped_homepage_details_start",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            homepage_url: action.payload.homepageUrl,
          },
        })
      );
    }
  };
const trackApprovedScrapedHomepageDetailsEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackApprovedScrapedHomepageDetailsEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_approved_scraped_homepage_details_end",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            homepage_url: action.payload.homepageUrl,
          },
        })
      );
    }
  };
const trackRequestedEditPostTextHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackRequestedEditPostText.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_requested_edit_post_text",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            post_id: action.payload.postId,
            platform: action.payload.platform,
            posting_date: action.payload.postingDate,
          },
        })
      );
    }
  };

//OAUTH CONNECT HANDLERS
const trackOauthConnectStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackOauthConnectStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_oauth_connect_start",
          properties: {
            provider: action.payload.provider,
            organization_id: organizationId,
            brand_id: action.payload.brandId,
          },
        })
      );
    }
  };
const trackOauthConnectEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackOauthConnectEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_oauth_connect_end",
          properties: {
            provider: action.payload.provider,
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            status: action.payload.status,
          },
        })
      );
    }
  };
const trackOauthConnectRedirectHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackOauthConnectRedirect.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_oauth_connect_redirect",
          properties: {
            provider: action.payload.provider,
            organization_id: organizationId,
            brand_id: action.payload.brandId,
          },
        })
      );
    }
  };
const trackOauthProviderPostingPageSelectionStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackOauthProviderPostingPageSelectionStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_oauth_provider_posting_page_selection_start",
          properties: {
            provider: action.payload.provider,
            organization_id: organizationId,
            brand_id: action.payload.brandId,
          },
        })
      );
    }
  };
const trackOauthProviderPostingPageSelectionEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackOauthProviderPostingPageSelectionEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_oauth_provider_posting_page_selection_end",
          properties: {
            provider: action.payload.provider,
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            status: action.payload.status,
          },
        })
      );
    }
  };

const trackEditedScrapedHomepageDetailsHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackEditedScrapedHomepageDetails.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_edited_scraped_homepage_details",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            field: action.payload.field,
          },
        })
      );
    }
  };

//BRAND ICON EVENTS
const trackBrandIconPresignStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackBrandIconPresignStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: `client_brand_icon_presign_start`,
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            file_type: action.payload.fileType,
          },
        })
      );
    }
  };

const trackBrandIconPresignEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackBrandIconPresignEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: `client_brand_icon_presign_end`,
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            file_type: action.payload.fileType,
            status: action.payload.status,
          },
        })
      );
    }
  };
const trackBrandIconUploadedStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackBrandIconUploadedStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_brand_icon_uploaded_start",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            presigned_url: action.payload.presignedUrl,
          },
        })
      );
    }
  };

const trackBrandIconUploadedEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackBrandIconUploadedEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: `client_brand_icon_uploaded_end`,
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            presigned_url: action.payload.presignedUrl,
            status: action.payload.status,
          },
        })
      );
    }
  };

const trackBrandEditedHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackBrandEdited.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: `client_brand_edited`,
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            field: action.payload.field,
          },
        })
      );
    }
  };

const trackBrandUpdatedStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackBrandUpdatedStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: `client_brand_updated_start`,
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            ...transformBrand.toResponse(action.payload.updatedBrand),
          },
        })
      );
    }
  };

const trackBrandUpdatedEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackBrandUpdatedEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: `client_brand_updated_end`,
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            status: action.payload.status,
            ...transformBrand.toResponse(action.payload.updatedBrand),
          },
        })
      );
    }
  };

const trackLocaleRequestedStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackLocaleRequestedStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: `client_locale_requested_start`,
          properties: {
            organization_id: organizationId,
            locale: action.payload.locale,
          },
        })
      );
    }
  };

const trackLocaleRequestedEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackLocaleRequestedEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: `client_locale_requested_end`,
          properties: {
            organization_id: organizationId,
            locale: action.payload.locale,
            status: action.payload.status,
          },
        })
      );
    }
  };

const trackWebsiteLanguageUpdatedHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackWebsiteLanguageUpdated.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: `client_website_language_updated`,
          properties: {
            organization_id: organizationId,
            locale: action.payload.locale,
          },
        })
      );
    }
  };

const trackChargebeeRedirectToCheckoutHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackRedirectToChargebeeCheckout.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_chargebee_redirect_to_checkout",
          properties: {
            organization_id: organizationId,
            plan_item_id: action.payload.PlanItemId,
            redirect_to: action.payload.redirectTo,
          },
        })
      );
    }
  };

const trackChargebeeRedirectToSelfServePortalHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackRedirectToChargebeeSelfServePortal.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_chargebee_redirect_to_self_serve_portal",
          properties: {
            organization_id: organizationId,
            redirect_to: action.payload.redirectTo,
          },
        })
      );
    }
  };

const trackCheckoutCallbackReceivedHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackCheckoutCallbackReceived.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_checkout_callback_received",
          properties: {
            organization_id: organizationId,
            status: action.payload.status,
            subscription_id: action.payload.subscriptionId,
            invoice_id: action.payload.invoiceId,
          },
        })
      );
    }
  };

const trackChargebeeSelfServePortalLoadedHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackChargebeeSelfServePortalLoaded.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_chargebee_self_serve_portal_loaded",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
          },
        })
      );
    }
  };

const trackChargebeeSelfServePortalPageVisitedHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackChargebeeSelfServePortalPageVisited.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_chargebee_self_serve_portal_page_visited",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            message_data: action.payload.messageData,
          },
        })
      );
    }
  };

const trackChargebeeSelfServePortalSubscriptionReactivatedHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackChargebeeSelfServePortalSubscriptionReactivated.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_chargebee_self_serve_portal_subscription_reactivated",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            message_data: action.payload.messageData,
          },
        })
      );
    }
  };

const trackChargebeeSelfServePortalSubscriptionCancelledHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackChargebeeSelfServePortalSubscriptionCancelled.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_chargebee_self_serve_portal_subscription_cancelled",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            message_data: action.payload.messageData,
          },
        })
      );
    }
  };

const trackChargebeeCheckoutLoadedHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackChargebeeCheckoutLoaded.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_chargebee_checkout_loaded",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
          },
        })
      );
    }
  };

const trackChargebeeCheckoutPageVisitedHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackChargebeeCheckoutPageVisited.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_chargebee_checkout_page_visited",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            message_data: action.payload.messageData,
          },
        })
      );
    }
  };

const trackChargebeeCheckoutSetHpTokenHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackChargebeeCheckoutSetHpToken.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_chargebee_checkout_set_hp_token",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            message_data: action.payload.messageData,
          },
        })
      );
    }
  };

const trackJoinWaitlistHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackJoinWaitingList.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: `client_join_waiting_list`,
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
          },
        })
      );
    }
  };

const trackContentSlotUpdatedStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackContentSlotUpdatedStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            post_id: action.payload.postId,
            posting_date: action.payload.postingDate,
            platform: action.payload.platform,
            approved_to_post: action.payload.approvedToPost,
          },
          eventName: `client_content_slot_updated_start`,
        })
      );
    }
  };

const trackContentSlotUpdatedEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackContentSlotUpdatedEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: `client_content_slot_updated_end`,
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            post_id: action.payload.postId,
            posting_date: action.payload.postingDate,
            platform: action.payload.platform,
            approved_to_post: action.payload.approvedToPost,
            status: action.payload.status,
          },
        })
      );
    }
  };

const trackPostBlurredHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackPostBlurred.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      const { brandId, postId, platform, postingDate, approvedToPost } = action.payload;
      dispatch(
        baseTrack({
          eventName: `client_post_blurred`,
          properties: {
            organization_id: organizationId,
            brand_id: brandId,
            post_id: postId,
            posting_date: postingDate,
            platform: platform,
            approved_to_post: approvedToPost,
          },
        })
      );
    }
  };

const trackPostViewedHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackPostViewed.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_post_viewed",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            post_id: action.payload.postId,
            posting_date: action.payload.postingDate,
            platform: action.payload.platform,
            approved_to_post: action.payload.approvedToPost,
            locked_by_scheduler: action.payload.lockedByScheduler,
            successfully_posted: action.payload.successfullyPosted,
          },
        })
      );
    }
  };

const trackBrandCalendarSlotOverridesUpdatedStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackBrandCalendarSlotOverridesUpdatedStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      const { brandId, ...overridesUpdatedEvent } = action.payload;
      const transformedOverrides =
        transformBrandCalendarSlotOverridesUpdatedEvent.toResponse(overridesUpdatedEvent);
      dispatch(
        baseTrack({
          eventName: "client_brand_calendar_slot_overrides_updated_start",
          properties: {
            organization_id: organizationId,
            brand_id: brandId,
            posting_date: action.payload.postingDate,
            platform: action.payload.platform,
            post_id: action.payload.postId,
            overrides: transformedOverrides,
          },
        })
      );
    }
  };

const trackBrandCalendarSlotOverridesUpdatedEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackBrandCalendarSlotOverridesUpdatedEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      const { brandId, ...overridesUpdatedEvent } = action.payload;
      const transformedOverrides =
        transformBrandCalendarSlotOverridesUpdatedEvent.toResponse(overridesUpdatedEvent);
      dispatch(
        baseTrack({
          eventName: "client_brand_calendar_slot_overrides_updated_end",
          properties: {
            organization_id: organizationId,
            brand_id: brandId,
            status: action.payload.status,
            posting_date: action.payload.postingDate,
            platform: action.payload.platform,
            post_id: action.payload.postId,
            overrides: transformedOverrides,
          },
        })
      );
    }
  };

const trackPublishNowHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackPublishNow.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_publish_now_for_facebook_testers",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            post_id: action.payload.postId,
            posting_date: action.payload.postingDate,
            platform: action.payload.platform,
            approved_to_post: action.payload.approvedToPost,
          },
        })
      );
    }
  };

const trackPostImagePresignStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackPostImagePresignStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_post_image_presign_start",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            post_id: action.payload.postId,
            file_type: action.payload.fileType,
            posting_date: action.payload.postingDate,
            platform: action.payload.platform,
          },
        })
      );
    }
  };

const trackPostImagePresignEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackPostImagePresignEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_post_image_presign_end",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            post_id: action.payload.postId,
            file_type: action.payload.fileType,
            status: action.payload.status,
            platform: action.payload.platform,
            posting_date: action.payload.postingDate,
            postImageUrl: action.payload.postImageUrl,
            presigned_url: action.payload.presignedUrl,
          },
        })
      );
    }
  };

const trackPostImageUploadedStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackPostImageUploadedStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_post_image_uploaded_start",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            post_id: action.payload.postId,
            presigned_url: action.payload.presignedUrl,
            post_image_url: action.payload.postImageUrl,
            posting_date: action.payload.postingDate,
            platform: action.payload.platform,
          },
        })
      );
    }
  };

const trackPostImageUploadedEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackPostImageUploadedEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_post_image_uploaded_end",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            post_id: action.payload.postId,
            presigned_url: action.payload.presignedUrl,
            status: action.payload.status,
            posting_date: action.payload.postingDate,
            platform: action.payload.platform,
          },
        })
      );
    }
  };

const trackPostingPagesListStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackPostingPagesListStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_posting_pages_list_start",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            provider: action.payload.provider,
          },
        })
      );
    }
  };

const trackPostingPagesListEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackPostingPagesListEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_posting_pages_list_end",
          properties: {
            organization_id: organizationId,
            brand_id: action.payload.brandId,
            provider: action.payload.provider,
            status: action.payload.status,
          },
        })
      );
    }
  };

const trackRejectedApiQueryHandler: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackRejectedApiQuery.match(action)) {
      dispatch(
        baseTrack({
          eventName: "client_rejected_api_query",
          properties: {
            action: action.payload.action,
          },
        })
      );
    }
  };

const trackRejectedApiMutationHandler: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackRejectedApiMutation.match(action)) {
      dispatch(
        baseTrack({
          eventName: "client_rejected_api_mutation",
          properties: {
            action: action.payload.action,
          },
        })
      );
    }
  };

const trackApiRejectedResponsesHandler: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (action && typeof action === "object") {
      const hasType = Object.hasOwn(action, "type");
      if (!hasType) return;
      const { type } = action as Action;
      if (type.endsWith("executeQuery/rejected")) {
        dispatch(trackRejectedApiQuery({ action: JSON.parse(JSON.stringify(action)) }));
      }
      if (type.endsWith("executeMutation/rejected")) {
        dispatch(trackRejectedApiMutation({ action: JSON.parse(JSON.stringify(action)) }));
      }
    }
  };

const trackStaticAssetsLocaleNamespaceLoadingFailedHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackStaticAssetsLocaleNamespaceLoadingFailed.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_static_assets_locale_namespace_loading_failed",
          properties: {
            organization_id: organizationId,
            namespace: action.payload.namespace,
            language: action.payload.language,
            error: action.payload.error,
          },
        })
      );
    }
  };

const trackStaticAssetsFontsInitializeStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackStaticAssetsFontsInitializeStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_static_assets_fonts_initialize_start",
          properties: {
            organization_id: organizationId,
          },
        })
      );
    }
  };

const trackLoginCallbackStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackLoginCallbackStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_login_callback_start",
          properties: {
            organization_id: organizationId,
            id_token_is_present: action.payload.idTokenIsPresent,
          },
        })
      );
    }
  };

const trackStaticAssetsFontsInitializeEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackStaticAssetsFontsInitializeEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_static_assets_fonts_initialize_end",
          properties: {
            organization_id: organizationId,
            status: action.payload.status,
          },
        })
      );
    }
  };

const trackLoginCallbackEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackLoginCallbackEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_login_callback_end",
          properties: {
            organization_id: organizationId,
            status: action.payload.status,
            redirected_to_path: action.payload.redirectedToPath,
          },
        })
      );
    }
  };

const trackLoginIdTokenVerifiedStartHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackLoginIdTokenVerifiedStart.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_login_id_token_verified_start",
          properties: {
            organization_id: organizationId,
            cognito_client_id: action.payload.cognitoClientId,
            cognito_user_pool_id: action.payload.cognitoUserPoolId,
          },
        })
      );
    }
  };

const trackLoginIdTokenVerifiedEndHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackLoginIdTokenVerifiedEnd.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_login_id_token_verified_end",
          properties: {
            organization_id: organizationId,
            status: action.payload.status,
            is_admin: action.payload.isAdmin,
          },
        })
      );
    }
  };

const trackClientRouterPathChangedHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (trackClientRouterPathChanged.match(action)) {
      const state = getState() as RootState;
      const { organizationId } = state.auth;
      dispatch(
        baseTrack({
          eventName: "client_router_path_changed",
          properties: {
            organization_id: organizationId,
            path: action.payload.path,
          },
        })
      );
    }
  };

export const analyticsMiddleware = [
  trackClientRouterPathChangedHandler,
  trackRejectedApiQueryHandler,
  trackRejectedApiMutationHandler,
  trackApiRejectedResponsesHandler,
  trackStaticAssetsFontsInitializeStartHandler,
  trackStaticAssetsLocaleNamespaceLoadingFailedHandler,
  trackStaticAssetsFontsInitializeEndHandler,
  trackJoinWaitlistHandler,
  baseTrackHandler,
  trackLoginCallbackEndHandler,
  trackLoginCallbackStartHandler,
  trackLoginIdTokenVerifiedEndHandler,
  trackLoginIdTokenVerifiedStartHandler,
  trackRedirectToLoginPageHandler,
  identifyUserHandler,
  resetUserHandler,
  trackLogoutStartHandler,
  trackLogoutEndHandler,
  trackBrandAddedStartHandler,
  trackBrandAddedEndHandler,
  trackScrapedHomepageDetailsStartHandler,
  trackScrapedHomepageDetailsEndHandler,
  trackApprovedScrapedHomepageDetailsStartHandler,
  trackApprovedScrapedHomepageDetailsEndHandler,
  trackOauthConnectStartHandler,
  trackOauthConnectEndHandler,
  trackEditedScrapedHomepageDetailsHandler,
  trackOauthConnectRedirectHandler,
  trackOauthProviderPostingPageSelectionStartHandler,
  trackOauthProviderPostingPageSelectionEndHandler,
  trackPostViewedHandler,
  trackBrandIconPresignStartHandler,
  trackBrandIconPresignEndHandler,
  trackBrandIconUploadedStartHandler,
  trackBrandIconUploadedEndHandler,
  trackBrandEditedHandler,
  trackBrandUpdatedStartHandler,
  trackBrandUpdatedEndHandler,
  trackLocaleRequestedStartHandler,
  trackLocaleRequestedEndHandler,
  trackWebsiteLanguageUpdatedHandler,
  trackContentSlotUpdatedStartHandler,
  trackContentSlotUpdatedEndHandler,
  trackPostBlurredHandler,
  trackBrandCalendarSlotOverridesUpdatedEndHandler,
  trackPublishNowHandler,
  trackPostImagePresignStartHandler,
  trackPostImagePresignEndHandler,
  trackPostImageUploadedStartHandler,
  trackPostImageUploadedEndHandler,
  trackPostingPagesListStartHandler,
  trackPostingPagesListEndHandler,
  trackChargebeeRedirectToCheckoutHandler,
  trackChargebeeRedirectToSelfServePortalHandler,
  trackCheckoutCallbackReceivedHandler,
  trackChargebeeSelfServePortalLoadedHandler,
  trackChargebeeSelfServePortalPageVisitedHandler,
  trackChargebeeSelfServePortalSubscriptionReactivatedHandler,
  trackChargebeeSelfServePortalSubscriptionCancelledHandler,
  trackChargebeeCheckoutLoadedHandler,
  trackChargebeeCheckoutPageVisitedHandler,
  trackChargebeeCheckoutSetHpTokenHandler,
  trackBrandCalendarSlotOverridesUpdatedStartHandler,
  trackRequestedEditPostTextHandler,
];
