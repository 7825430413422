import { localeIcon, localeLabel, SupportedLocale } from "types/supportedLocale.type";
import i18n from "services/i18n/i18n.service";
import { Formik } from "formik";
import { useAppDispatch } from "hooks/redux.hooks";
import {
  trackLocaleRequestedEnd,
  trackLocaleRequestedStart,
  trackWebsiteLanguageUpdated,
} from "state/features/analytics/analyticsActions";
import { MenuItem, Select } from "@mui/material";
import { Icon } from "components/Icon/Icon";
import { memo, useSyncExternalStore } from "react";
import { Loader } from "components/Loader/Loader";

const locales = Object.keys(SupportedLocale);

function subscribeToLanguageChange(callback: () => void) {
  i18n.on("languageChanged", callback);
  return () => {
    i18n.off("languageChanged", callback);
  };
}

export const SetWebsiteLanguage = memo(() => {
  const dispatch = useAppDispatch();
  const language = useSyncExternalStore(subscribeToLanguageChange, () => i18n.language);

  return (
    <Formik
      initialValues={{ websiteLanguage: language }}
      enableReinitialize={true}
      onSubmit={async ({ websiteLanguage }) => {
        dispatch(trackWebsiteLanguageUpdated({ locale: websiteLanguage }));
        dispatch(trackLocaleRequestedStart({ locale: websiteLanguage }));
        try {
          await i18n.changeLanguage(websiteLanguage);
          document.body.dir = i18n.dir();
          dispatch(trackLocaleRequestedEnd({ locale: websiteLanguage, status: "resolved" }));
        } catch (e) {
          console.error(e);
          dispatch(trackLocaleRequestedEnd({ locale: websiteLanguage, status: "rejected" }));
        }
      }}
    >
      {(props) => (
        <Select
          dir="ltr"
          value={props.values.websiteLanguage}
          onChange={(e) => {
            props.setFieldValue("websiteLanguage", e.target.value);
            props.handleSubmit();
          }}
          IconComponent={() => <ArrowIcon hide={props.isSubmitting} />}
          sx={{
            "& fieldset": {
              border: "0",
            },
            "& .MuiSelect-select": {
              fontFamily: "Rubik",
              fontSize: "14px",
              fontWeight: "500",
              borderRadius: "4px",
              backgroundColor: "#57576F",
              paddingX: "8.52px",
              paddingY: "3.81px",
              color: "#F8F8F8",
              minWidth: "80px",
            },
          }}
          MenuProps={{
            sx: {
              "& .MuiPaper-root": {
                backgroundColor: "#57576F",
                marginY: "15px",
                borderRadius: "4px",
                boxShadow: "0px 0px 50px 0px rgba(0, 0, 0, 0.25)",
              },
              "& .MuiList-root": {
                padding: "0",
              },
              "& ul": {
                "& li:first-of-type": {
                  paddingBlockStart: "9px",
                },
                "& li:last-of-type": {
                  paddingBlockEnd: "9px",
                },
                "& .Mui-selected": {
                  backgroundColor: "#191B22",
                },
                "& li:hover": {
                  backgroundColor: "#2D303D",
                },
              },
              "& li": {
                color: "#F8F8F8",
                fontSize: "12px",
                fontWeight: "400",
                paddingX: "10px",
                paddingY: "6px",
                height: "36px",
              },
            },
          }}
          slotProps={{
            root: {
              sx: {
                borderColor: "transparent",
              },
            },
            input: {
              sx: {},
            },
          }}
          disabled={props.isSubmitting}
        >
          {locales.map((locale) => (
            <MenuItem value={locale} key={locale}>
              {props.isSubmitting ? (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Loader size={15} color="white" />
                </div>
              ) : (
                <div className="flex gap-[7px] items-center relative">
                  <Icon iconKey={localeIcon[locale as SupportedLocale]} width={24} height={24} />
                  <span>{localeLabel[locale as SupportedLocale]}</span>
                </div>
              )}
            </MenuItem>
          ))}
        </Select>
      )}
    </Formik>
  );
});

const ArrowIcon = ({ hide }: { hide?: boolean }) => {
  if (hide) return null;
  return <Icon iconKey="ArrowDark" className="absolute end-[4%] pointer-events-none rotate-90" />;
};
