import { useAppSelector } from "hooks/redux.hooks";
import { useMemo } from "react";

export const useAuth = () => {
  const auth = useAppSelector((state) => state.auth);
  return useMemo(() => {
    return {
      auth,
    };
  }, [auth]);
};
