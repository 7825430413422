import { useParams } from "react-router-dom";
import { useGetBrandByIdQuery } from "api/services/brand/brand.service";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";

let disabledForSession = false;

function getLastTimeDismissed(brandId: string) {
  return localStorage.getItem(`autopilotPushDismissed-${brandId}`);
}

export function setLastTimeDismissed(brandId: string) {
  localStorage.setItem(`autopilotPushDismissed-${brandId}`, new Date().toISOString());
}

function isDismissedInLastDay(brandId: string) {
  const lastDismissed = getLastTimeDismissed(brandId);
  if (!lastDismissed) return false;
  const timeOneDayAgo = DateTime.now().minus({ days: 1 });
  return timeOneDayAgo < DateTime.fromISO(lastDismissed);
}

export function useAutopilotPush() {
  const [show, setShow] = useState(false);
  const { brandId } = useParams<{ brandId: string }>();
  if (!brandId) throw new Error("brandId is required");
  const brandQuery = useGetBrandByIdQuery({ brandId: brandId! });

  useEffect(() => {
    // if autopilot is disabled, show notification
    if (brandQuery.isLoading || brandQuery.isUninitialized) return;
    if (brandQuery.data?.brandSettings?.socialMediaAutopilot) return;
    if (isDismissedInLastDay(brandId)) return;
    setShow(true);
  }, [
    brandId,
    brandQuery.data?.brandSettings?.socialMediaAutopilot,
    brandQuery.isLoading,
    brandQuery.isUninitialized,
  ]);

  function dismissForSession() {
    disabledForSession = true;
    setShow(false);
    setLastTimeDismissed(brandId!);
  }

  return { show: show && !disabledForSession, dismissForSession };
}
