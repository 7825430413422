import React from "react";
import { Trans, useTranslation } from "react-i18next";

type Elements = Pick<React.ReactHTML, "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "a">;

export type TextVar = { [key: string]: unknown };

export interface TranslateProps {
  translationKey?: string;
  text?: string;
  vars?: TextVar;
  el?: keyof Elements;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
}

export const AppText = ({
  translationKey,
  text,
  vars,
  el,
  className,
  children,
  style,
}: TranslateProps) => {
  const { t, i18n } = useTranslation();
  let translation;

  if (text) {
    if (i18n.exists(translationKey as string)) {
      translation = (
        <Trans i18nKey={translationKey} t={t} values={vars}>
          {children}
        </Trans>
      );
    } else translation = <>{text}</>;
  } else {
    translation = (
      <Trans i18nKey={translationKey} t={t} values={vars}>
        {children}
      </Trans>
    );
  }

  const element = el ? React.createElement(el, { className, style }, translation) : translation;

  return element;
};
