import { Middleware } from "@reduxjs/toolkit";
import { setLastVisitedPath, setPageView } from "state/features/history/historyActions";
import { RootState } from "types/state.type";
import { trackClientRouterPathChanged } from "state/features/analytics/analyticsActions";

export const setPageViewMap: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (setPageView.match(action)) {
      const path = action.payload;
      const state = getState() as RootState;
      const currentLastVisitedPath = state.history.lastVisitedPath;
      if (currentLastVisitedPath !== path) {
        dispatch(setLastVisitedPath(path));
        dispatch(trackClientRouterPathChanged({ path }));
      }
    }
  };

export const historyMiddleware = [setPageViewMap];
