import tw from "tailwind-styled-components";
import { BrandMenu } from "components/BrandMenu/BrandMenu";
import { Link, useParams } from "react-router-dom";
import { Icon } from "components/Icon/Icon";
import { SetWebsiteLanguage } from "components/NavBar/SetWebsiteLanguage";
import { memo } from "react";

export const Navbar = memo(() => {
  const { brandId } = useParams<{ brandId: string }>();
  const homeButtonTo = brandId ? `/brands/${brandId}` : "/brands/not-found";

  return (
    <NavBarContainer dir="ltr">
      <Link to={homeButtonTo} key="link-home">
        <Icon iconKey="MyElla" width={75} />
      </Link>
      <div className="flex gap-[53px]">
        <SetWebsiteLanguage />
        <BrandMenu />
      </div>
    </NavBarContainer>
  );
});

const NavBarContainer = tw.nav`
  bg-primary
  flex
  items-center
  px-[50px]
  gap-x-[10px]
  justify-between
  shadow-[0px_4px_12px_0px_rgba(0,_0,_0,_0.25)]
  py-[7px]
  z-[100]
`;
