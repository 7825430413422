// "3e36d11c-5a00-4f6b-884c-2d878b7a1185": {
//   "post_id": "3e36d11c-5a00-4f6b-884c-2d878b7a1185",
//     "content": "\ud83c\udf38 Pop of Pink to Perfect Your Look! \ud83c\udf38\n\nLadies, let's make every day a little brighter with the EVELYN BAG in PINK from Steve Madden \ud83d\udc95 This isn't just any handbag - it's your new go-to accessory that is CLASSIC, SMART, and oh-so CHARMING!\n\nFrom running to meetings \ud83c\udfc3\u200d\u2640\ufe0f\ud83d\udcbc to brunch with friends \ud83e\udd42\ud83c\udf73, the #EvelynBag brings a surprisingly spacious interior for all your essentials. With clever organization, a matching modular zip pouch, and reinforced edges - this bag is both practical and on-trend.\u2728\n\nCrafted with luxe manmade materials and custom branded hardware, this structured top-handle bag adds a substantial touch to any outfit. Plus, with the detachable zipper pouch and adjustable crossbody strap, you get versatility along with style! \ud83d\udc5c\n\nReady to turn heads? Shop the EVELYN BAG PINK \ud83d\udc5b with FREE shipping on orders $50+! Become a part of the #SteveMadden family and enjoy the perks of #SMPass today.\n\nDon't wait - Buy now and pay later! \ud83d\udcb3 To make this beauty yours, click <a href=\"https://www.stevemadden.com/products/dt618175-pink\">here</a>!\n\n#SteveMadden #HandbagsLove #PopOfColor #FashionForward #AccessoryGoals #FreeShipping #ShopNow",
//     "image_url": "https://cdn.shopify.com/s/files/1/2170/8465/files/STEVEMADDEN_HANDBAGS_BEVELYN_PINK_01.jpg?v=1699284022",
//     "reasoning": {
//     "product": {
//       "reason": "created",
//         "product_link": "https://www.stevemadden.com/products/dt618175-pink"
//     }
//   }
// }

import { replaceAllObjKeys } from "utils/trasnformResponseFromApi/replaceAllObjKeys";
import { ContentCalendarPost } from "types/contentCalendarPost.type";

const transformContentCalendarPostKeys: Record<string, string> = {
  post_id: "postId",
  content: "content",
  image_url: "imageUrl",
  reasoning: "reasoning",
  product: "product",
  blog: "blog",
  reason: "reason",
  product_link: "productLink",
  blog_link: "blogLink",
};

function fromResponse(
  response: Record<keyof typeof transformContentCalendarPostKeys, unknown>
): ContentCalendarPost {
  return replaceAllObjKeys<
    ContentCalendarPost,
    Record<keyof typeof transformContentCalendarPostKeys, unknown>
  >(response, (key) => {
    const newKey = transformContentCalendarPostKeys[key];
    return newKey || key;
  });
}

function toResponse(contentCalendarPost: Partial<ContentCalendarPost>): Record<string, unknown> {
  return replaceAllObjKeys<Record<string, unknown>, Partial<ContentCalendarPost>>(
    contentCalendarPost,
    (key) => {
      const newKey = Object.keys(transformContentCalendarPostKeys).find(
        (k) => transformContentCalendarPostKeys[k] === key
      );
      return newKey || key;
    }
  );
}

export default { fromResponse, toResponse };
