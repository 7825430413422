import { createApi } from "@reduxjs/toolkit/query/react";
import {
  BrandCalendarSlotOverridesUpdatedEvent,
  ContentCalendarSchedule,
} from "types/contentCalendarSchedule.type";
import { dynamicBaseQuery } from "utils/dynamicBaseQuery";
import { ContentCalendar } from "types/contentCalendar.type";
import transformContentCalendarPost from "utils/trasnformResponseFromApi/transformContentCalendarPost";
import { ContentCalendarPost } from "types/contentCalendarPost.type";
import transformContentCalendarSchedule from "utils/trasnformResponseFromApi/transformContentCalendarSchedule";
import {
  trackBrandCalendarSlotOverridesUpdatedEnd,
  trackBrandCalendarSlotOverridesUpdatedStart,
  trackContentSlotUpdatedEnd,
  trackContentSlotUpdatedStart,
  trackPostImagePresignEnd,
  trackPostImagePresignStart,
  trackPostImageUploadedEnd,
  trackPostImageUploadedStart,
} from "state/features/analytics/analyticsActions";
import transformBrandCalendarSlotOverridesUpdatedEvent from "utils/trasnformResponseFromApi/transformBrandCalendarSlotOverridesUpdatedEvent";

const contentService = createApi({
  reducerPath: "contentService",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["ContentCalendarSchedule"],
  endpoints: (builder) => ({
    updateBrandContentCalendarSlotOverrides: builder.mutation<
      boolean,
      {
        contentCalendarSlotOverridesUpdatedEvent: BrandCalendarSlotOverridesUpdatedEvent;
        brandId: string;
      }
    >({
      queryFn: async (
        { contentCalendarSlotOverridesUpdatedEvent, brandId },
        _,
        _arg,
        fetchWithBQ
      ) => {
        const transformedEvent = transformBrandCalendarSlotOverridesUpdatedEvent.toResponse(
          contentCalendarSlotOverridesUpdatedEvent
        );
        try {
          const res = await fetchWithBQ({
            method: "PUT",
            body: transformedEvent,
            url: `brand/${brandId}/brand-calendar-slot-overrides-updated`,
            retryOptions: {
              maxRetries: 2,
            },
          });
          if (!res.error) {
            return { data: true };
          } else {
            return { error: { status: "CUSTOM_ERROR", error: "No data returned" } };
          }
        } catch (error) {
          return { error: { status: "FETCH_ERROR", error: `Failed to update brand: ${error}` } };
        }
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        let status: "resolved" | "rejected" = "resolved";
        dispatch(
          trackBrandCalendarSlotOverridesUpdatedStart({
            brandId: arg.brandId,
            postingDate: arg.contentCalendarSlotOverridesUpdatedEvent.postingDate,
            postId: arg.contentCalendarSlotOverridesUpdatedEvent.postId,
            overrides: arg.contentCalendarSlotOverridesUpdatedEvent.overrides,
            platform: arg.contentCalendarSlotOverridesUpdatedEvent.platform,
          })
        );
        try {
          await queryFulfilled;
        } catch (e) {
          status = "rejected";
        } finally {
          dispatch(
            trackBrandCalendarSlotOverridesUpdatedEnd({
              brandId: arg.brandId,
              postingDate: arg.contentCalendarSlotOverridesUpdatedEvent.postingDate,
              postId: arg.contentCalendarSlotOverridesUpdatedEvent.postId,
              overrides: arg.contentCalendarSlotOverridesUpdatedEvent.overrides,
              platform: arg.contentCalendarSlotOverridesUpdatedEvent.platform,
              status,
            })
          );
        }
      },
    }),
    uploadBrandCalendarSlotOverridesUserImage: builder.mutation<
      { uploaded: boolean },
      {
        file: File;
        brandId: string;
        presignedUrl: string;
        postId: string;
        platform: string;
        postingDate: string;
        postImageUrl: string;
        fields: Record<string, string>;
      }
    >({
      queryFn: async ({ file, presignedUrl, fields }) => {
        const formData = new FormData();
        Object.entries(fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", file);
        try {
          const res = await fetch(presignedUrl, {
            method: "POST",
            body: formData,
          });
          if (!res.ok) {
            return {
              error: { status: "FETCH_ERROR", error: `Failed to upload image: ${res.statusText}` },
            };
          }
          return { data: { uploaded: true } };
        } catch (error) {
          return { error: { status: "FETCH_ERROR", error: `Failed to upload image: ${error}` } };
        }
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        let status: "resolved" | "rejected" = "resolved";
        dispatch(
          trackPostImageUploadedStart({
            brandId: arg.brandId,
            presignedUrl: arg.presignedUrl,
            postId: arg.postId,
            postingDate: arg.postingDate,
            platform: arg.platform,
            postImageUrl: arg.postImageUrl,
          })
        );
        try {
          await queryFulfilled;
        } catch (e) {
          status = "rejected";
          return;
        } finally {
          dispatch(
            trackPostImageUploadedEnd({
              brandId: arg.brandId,
              presignedUrl: arg.presignedUrl,
              status,
              postId: arg.postId,
              postingDate: arg.postingDate,
              platform: arg.platform,
            })
          );
        }
      },
    }),
    getCalendarSlotOverridesUserImagePresignedUrl: builder.mutation<
      {
        presignedUrl: string;
        postImageUrl: string;
        fields: Record<string, string>;
      },
      {
        fileType: string;
        postId: string;
        brandId: string;
        platform: string;
        postingDate: string;
      }
    >({
      queryFn: async ({ brandId, fileType, postId, platform }, api, _arg, fetchWithBQ) => {
        try {
          const { data } = await fetchWithBQ({
            method: "POST",
            body: {
              file_type: fileType,
            },
            url: `brand/${brandId}/${postId}/${platform}/prepare-post-image-upload`,
            retryOptions: {
              maxRetries: 2,
            },
          });
          const { presigned_url, post_image_url, fields } = data as {
            presigned_url: string;
            post_image_url: string;
            fields: Record<string, string>;
          };

          if (!presigned_url || !post_image_url || !fields) {
            return { error: { status: "CUSTOM_ERROR", error: "No data returned" } };
          }
          return { data: { presignedUrl: presigned_url, postImageUrl: post_image_url, fields } };
        } catch (error) {
          return {
            error: { status: "FETCH_ERROR", error: `Failed to get presigned url: ${error}` },
          };
        }
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        let status: "resolved" | "rejected" = "resolved";
        let res: { data: { presignedUrl: string; postImageUrl: string } } | undefined = undefined;

        dispatch(
          trackPostImagePresignStart({
            brandId: arg.brandId,
            fileType: arg.fileType,
            postingDate: arg.postingDate,
            postId: arg.postId,
            platform: arg.platform,
          })
        );
        try {
          res = await queryFulfilled;
        } catch (e) {
          status = "rejected";
        } finally {
          dispatch(
            trackPostImagePresignEnd({
              brandId: arg.brandId,
              fileType: arg.fileType,
              status,
              postId: arg.postId,
              platform: arg.platform,
              postingDate: arg.postingDate,
              postImageUrl: res?.data?.postImageUrl,
              presignedUrl: res?.data?.presignedUrl,
            })
          );
        }
      },
    }),
    updateContentApproval: builder.mutation<
      boolean,
      {
        data: Omit<
          ContentCalendarSchedule,
          "lockedByScheduler" | "successfullyPosted" | "overrides"
        >;
        brandId: string;
      }
    >({
      invalidatesTags: ["ContentCalendarSchedule"],
      queryFn: async ({ brandId, data }, api, _arg, fetchWithBQ) => {
        try {
          const res = await fetchWithBQ({
            method: "PUT",
            body: {
              post_id: data.postId,
              posting_date: data.postingDate,
              platform: data.platform,
              approved_to_post: data.approvedToPost,
            },
            url: `brand/${brandId}/brand-content-slot-updated`,
            retryOptions: {
              maxRetries: 2,
            },
          });

          if (!res.error) {
            return { data: true };
          } else {
            return { error: { status: "CUSTOM_ERROR", error: "No data returned" } };
          }
        } catch (error) {
          return { error: { status: "FETCH_ERROR", error: `Failed to update brand: ${error}` } };
        }
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(
          trackContentSlotUpdatedStart({
            brandId: arg.brandId,
            platform: arg.data.platform,
            postingDate: arg.data.postingDate,
            postId: arg.data.postId,
            approvedToPost: arg.data.approvedToPost,
          })
        );
        try {
          await queryFulfilled;
          dispatch(
            trackContentSlotUpdatedEnd({
              brandId: arg.brandId,
              platform: arg.data.platform,
              postingDate: arg.data.postingDate,
              postId: arg.data.postId,
              approvedToPost: arg.data.approvedToPost,
              status: "resolved",
            })
          );
        } catch (e) {
          dispatch(
            trackContentSlotUpdatedEnd({
              brandId: arg.brandId,
              platform: arg.data.platform,
              postingDate: arg.data.postingDate,
              postId: arg.data.postId,
              approvedToPost: arg.data.approvedToPost,
              status: "rejected",
            })
          );
          return;
        }
      },
    }),
    getBrandContentCalendarSchedule: builder.query<
      ContentCalendar,
      { brandId: string; startDate: number; endDate: number }
    >({
      providesTags: ["ContentCalendarSchedule"],
      query: ({ brandId, startDate, endDate }) => ({
        url: `brand/${brandId}/content-calendar?start_unix=${startDate}&end_unix=${endDate}`,
        retryOptions: {
          maxRetries: 2,
        },
      }),
      transformResponse: (response) => {
        const { posts, schedule } = response as {
          posts: Record<string, unknown>;
          schedule: unknown[];
        };
        if (!posts || !schedule) {
          throw new Error("Missing posts or schedule in getBrandContentCalendarSchedule response");
        }
        const transformedPosts = Object.keys(posts).reduce(
          (acc, currentId) => {
            const transformedPost = transformContentCalendarPost.fromResponse(
              posts[currentId] as Record<string, unknown>
            );
            return {
              ...acc,
              [currentId]: transformedPost,
            };
          },
          {} as { [key: string]: ContentCalendarPost }
        );
        const transformedSchedule = schedule.map((slot) =>
          transformContentCalendarSchedule.fromResponse(slot as Record<string, unknown>)
        );
        return { posts: transformedPosts, schedule: transformedSchedule };
      },
    }),
  }),
});

export const {
  useUpdateContentApprovalMutation,
  useGetBrandContentCalendarScheduleQuery,
  useGetCalendarSlotOverridesUserImagePresignedUrlMutation,
  useUpdateBrandContentCalendarSlotOverridesMutation,
  useUploadBrandCalendarSlotOverridesUserImageMutation,
  usePrefetch: usePrefetchContent,
} = contentService;

export { contentService };
