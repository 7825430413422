import { IconKey, iconUrl } from "assets/IconList";
import { useAppSelector } from "hooks/redux.hooks";

export interface IconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  iconKey: IconKey;
}

export const Icon = ({ iconKey, width = 24, height = 24, ...imgProps }: IconProps) => {
  const staticAssetsUrl = useAppSelector((state) => state.config.staticAssetsUrl);
  if (!staticAssetsUrl) throw new Error("staticAssetsUrl is not defined");
  const iconPath = staticAssetsUrl + "/" + iconUrl[iconKey];

  return <img width={width} height={height} src={iconPath} alt={iconKey} {...imgProps} />;
};
