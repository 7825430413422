import { Middleware } from "@reduxjs/toolkit";
import {
  initializeConfig,
  resetConfig,
  setConfigAdminOrganizationId,
  setConfigApiBaseUrl,
  setConfigCbSite,
  setConfigChaosEnabled,
  setConfigChaosUnlikelihood,
  setConfigChargebeeCheckoutOrigin,
  setConfigChargebeeCheckoutUrl,
  setConfigChargebeeSelfServePortalUrl,
  setConfigCognitoClientId,
  setConfigCognitoUserPoolId,
  setConfigEnvironment,
  setConfigFacebookTesterOrganizationId,
  setConfigHomepageUrl,
  setConfigLoginUrl,
  setConfigMixpanelToken,
  setConfigPlansProps,
  setConfigStaticAssetsUrl,
  setConfigStatus,
} from "state/features/config/configActions";

export const ResetConfigMap: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (resetConfig.match(action)) {
      dispatch(setConfigApiBaseUrl(undefined));
      dispatch(setConfigStaticAssetsUrl(undefined));
      dispatch(setConfigMixpanelToken(undefined));
      dispatch(setConfigEnvironment(undefined));
      dispatch(setConfigLoginUrl(undefined));
      dispatch(setConfigChargebeeCheckoutUrl(undefined));
      dispatch(setConfigChargebeeCheckoutOrigin(undefined));
      dispatch(setConfigChargebeeSelfServePortalUrl(undefined));
      dispatch(setConfigCognitoClientId(undefined));
      dispatch(setConfigCognitoUserPoolId(undefined));
      dispatch(setConfigAdminOrganizationId(undefined));
      dispatch(setConfigFacebookTesterOrganizationId(undefined));
      dispatch(setConfigCbSite(undefined));
      dispatch(setConfigPlansProps(undefined));
      dispatch(setConfigChaosEnabled(undefined));
    }
  };

export const initializeConfigMap: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (initializeConfig.match(action)) {
      // //TODO : load from backend based on state(connected user | brand...)
      dispatch(setConfigStatus("initializing"));
      dispatch(setConfigApiBaseUrl(import.meta.env.VITE_API_URL));
      dispatch(setConfigStaticAssetsUrl(import.meta.env.VITE_STATIC_ASSETS_URL));
      dispatch(setConfigHomepageUrl(import.meta.env.VITE_HOMEPAGE_URL));
      dispatch(setConfigLoginUrl(import.meta.env.VITE_LOGIN_URL));
      dispatch(setConfigCognitoClientId(import.meta.env.VITE_COGNITO_CLIENT_ID));
      dispatch(setConfigCognitoUserPoolId(import.meta.env.VITE_COGNITO_USER_POOL_ID));
      dispatch(setConfigChargebeeCheckoutUrl(import.meta.env.VITE_CHARGEBEE_CHECKOUT_URL));
      dispatch(setConfigChargebeeCheckoutOrigin(import.meta.env.VITE_CHARGEBEE_CHECKOUT_ORIGIN));
      dispatch(
        setConfigChargebeeSelfServePortalUrl(import.meta.env.VITE_CHARGEBEE_SELF_SERVE_PORTAL_URL)
      );
      dispatch(
        setConfigFacebookTesterOrganizationId(import.meta.env.VITE_FACEBOOK_TESTER_ORGANIZATION_ID)
      );
      try {
        const adminOrganizationId = JSON.parse(import.meta.env.VITE_ADMIN_ORGANIZATION_ID);
        dispatch(setConfigAdminOrganizationId(adminOrganizationId));
      } catch (e) {
        console.error("Error parsing admin organization id", e);
        dispatch(setConfigAdminOrganizationId([]));
      }
      dispatch(setConfigMixpanelToken(import.meta.env.VITE_MIXPANEL_TOKEN));
      dispatch(setConfigCbSite(import.meta.env.VITE_CB_SITE));
      dispatch(setConfigPlansProps(import.meta.env.VITE_PLANS_PROP));
      const env = (() => {
        switch (import.meta.env.MODE) {
          case "development":
            return "development";
          case "production":
            return "production";
          case "staging":
            return "staging";
          case "test":
            return "test";
          default:
            return "development";
        }
      })();
      dispatch(setConfigChaosEnabled(import.meta.env.VITE_ENABLE_CHAOS === "true"));
      dispatch(setConfigChaosUnlikelihood(parseInt(import.meta.env.VITE_CHAOS_UNLIKELIHOOD)));
      dispatch(setConfigEnvironment(env));
      dispatch(setConfigStatus("ready"));
    }
  };

export const configMiddleware = [ResetConfigMap, initializeConfigMap];
