import * as React from "react";
import tw from "tailwind-styled-components";
import { Link, LinkProps } from "react-router-dom";
import { HTMLMotionProps, motion } from "framer-motion";

const ButtonContainer = tw.button`
  whitespace-nowrap
  flex
  items-center
  justify-center
  text-sm
  text-buttonDisabled
  font-[400]
  bg-primary
  px-[15px]
  py-[6px]
  rounded-[5px]
  shadow-custom
`;

const LinkButtonContainer = tw(Link)`
  whitespace-nowrap
  flex
  items-center
  justify-center
  text-sm
  text-buttonDisabled
  bg-primary
  px-[15px]
  py-[6px]
  rounded-[5px]
  shadow-custom
  font-[400]
  box-border
`;

const MotionOutlinedGradientBtnContainer = motion(ButtonContainer);
const MotionOutlinedGradientLinkContainer = motion(LinkButtonContainer);

export const Button = ({
  type = "button",
  ...props
}: HTMLMotionProps<"button"> & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <MotionOutlinedGradientBtnContainer
    type={type}
    whileHover={{ scale: props.disabled ? 1 : 1.05 }}
    whileTap={{ scale: props.disabled ? 1 : 0.95 }}
    {...props}
  >
    {props.children}
  </MotionOutlinedGradientBtnContainer>
);

export const LinkButton = (
  props: HTMLMotionProps<"a"> & LinkProps & React.RefAttributes<HTMLAnchorElement>
) => (
  <MotionOutlinedGradientLinkContainer
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    {...props}
  >
    {props.children}
  </MotionOutlinedGradientLinkContainer>
);
