import { useAppSelector } from "hooks/redux.hooks";
import { useMemo } from "react";

export const useConfig = () => {
  const config = useAppSelector((state) => state.config);
  return useMemo(() => {
    return {
      config,
    };
  }, [config]);
};
