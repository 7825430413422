import { Loader } from "components/Loader/Loader";
import { useEffect, useMemo } from "react";
import { getUnixTime } from "date-fns";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useGetBrandIntegrationsQuery } from "api/services/integrations/integrations.service";
import { useCalendar } from "pages/brands/[brandId]/weekly-calendar/hooks/useCalendar";
import { usePrefetchContent } from "api/services/content/content.service";
import { useGetBrandByIdQuery } from "api/services/brand/brand.service";
import { useOnboarding } from "hooks/useOnboarding";

export function WithOnboarding() {
  const navigate = useNavigate();
  const { brandId } = useParams<{ brandId: string }>();
  const prefetchContentCalendar = usePrefetchContent("getBrandContentCalendarSchedule", {
    ifOlderThan: false,
  });
  if (!brandId) {
    throw new Error("Missing brandId in WithIntegrations");
  }
  const integrationsQuery = useGetBrandIntegrationsQuery({ brandId: brandId! }, { skip: !brandId });
  const brandQuery = useGetBrandByIdQuery({ brandId: brandId! }, { skip: !brandId });
  const { slots } = useCalendar({ timeZone: brandQuery.data?.brandSettings?.postingTz });

  const { brandHasFullyConnectedIntegrations } = useOnboarding({ brandId });

  const brandFinishedScrapingStep = useMemo(() => {
    if (brandQuery.isSuccess) {
      return !!brandQuery.data?.originalUrl;
    }
    return undefined;
  }, [brandQuery.data, brandQuery.isSuccess]);

  useEffect(() => {
    if (integrationsQuery.error) {
      navigate(`/brands/${brandId}`);
    }
  }, [brandId, integrationsQuery.error, navigate]);

  useEffect(() => {
    if (brandQuery.error) {
      navigate(`/brands/${brandId}`);
    }
  }, [brandId, brandQuery.error, navigate]);

  useEffect(() => {
    if (!slots) return;
    const firstSlotsStart = slots.at(0)?.start;
    const lastSlotsEnd = slots[slots.length - 1].end;
    if (!firstSlotsStart || !lastSlotsEnd) return;
    prefetchContentCalendar(
      {
        brandId,
        startDate: getUnixTime(firstSlotsStart),
        endDate: getUnixTime(lastSlotsEnd),
      },
      {
        ifOlderThan: false,
      }
    );
  }, [brandId, prefetchContentCalendar, slots]);

  useEffect(() => {
    if (!integrationsQuery.isSuccess) return;
    if (brandFinishedScrapingStep !== true) return;
    if (brandHasFullyConnectedIntegrations === false) {
      navigate(`/brands/${brandId}/connect-to-oauth-provider`);
    }
  }, [
    brandFinishedScrapingStep,
    brandHasFullyConnectedIntegrations,
    brandId,
    integrationsQuery.isSuccess,
    navigate,
  ]);

  useEffect(() => {
    if (!brandQuery.isSuccess) return;
    if (brandFinishedScrapingStep === false) {
      navigate(`/brands/${brandId}/mini-scrape`);
    }
  }, [brandFinishedScrapingStep, brandId, brandQuery.isSuccess, navigate]);

  if (integrationsQuery.isLoading) return <Loader />;
  if (brandHasFullyConnectedIntegrations) return <Outlet />;
}
