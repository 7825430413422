import { RootState } from "types/state.type";
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from "@reduxjs/toolkit/query";
import { RateLimiter } from "limiter";
import { RetryOptions } from "@reduxjs/toolkit/dist/query/retry";

const rawBaseQuery = fetchBaseQuery();

const rateLimiterStore = {} as Record<string, RateLimiter>;

export const dynamicBaseQuery: BaseQueryFn<
  string | (FetchArgs & { retryOptions?: RetryOptions }),
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const state: RootState = api.getState() as RootState;
  const baseUrl = state.config.apiBaseUrl;

  const urlEnd = typeof args === "string" ? args : args.url;
  let adjustedUrl = `${baseUrl}${urlEnd}`;

  // Russian roulette style chaos when chaos is enabled in the config
  // We have separate chaos blocks so we can ensure simulating different chaotic situations
  const chaosEnabled = state.config.chaosEnabled;
  const chaosUnlikelihood = state.config.chaosUnlikelihood;

  if (chaosEnabled && Math.floor(Math.random() * chaosUnlikelihood) === 0) {
    adjustedUrl = `${baseUrl}chaos/${urlEnd}`;
  }

  let token = state.auth?.idToken;
  if (chaosEnabled && Math.floor(Math.random() * chaosUnlikelihood) === 0) {
    token = "chaos";
  }

  const headers = new Headers();
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  let adjustedArgs: FetchArgs = {
    url: adjustedUrl,
    headers,
    credentials: "include",
  };
  if (typeof args !== "string") {
    adjustedArgs = { ...args, ...adjustedArgs };
  }

  rateLimiterStore[adjustedUrl] =
    rateLimiterStore[adjustedUrl] ??
    new RateLimiter({
      interval: 1000,
      tokensPerInterval: 3, // allow maximum 3 concurrent requests per second to the same endpoint
    });

  await rateLimiterStore[adjustedUrl].removeTokens(1);

  const retryOptions: RetryOptions = {
    retryCondition: (error, args, { attempt }) => {
      if (typeof args !== "string" && args.retryOptions?.retryCondition) {
        return args.retryOptions.retryCondition(error, args);
      }
      if (error.status === 401) {
        return false;
      }
      return attempt <= (args.retryOptions?.maxRetries ?? 3);
    },
  };

  return retry(rawBaseQuery, retryOptions)(adjustedArgs, api, extraOptions);
};
