enum APIS {
  AUTH = "authService",
  CONTENT = "contentService",
  BRAND = "brandService",
  SCRAPER = "scraperService",
  SUBSCRIPTION = "subscriptionService",
  INTEGRATIONS = "integrationsService",
}

export { APIS };
