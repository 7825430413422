import {
  removeBrandNotification,
  setBrandNotification,
  upsertBrandNotification,
} from "state/features/brandNotifications/brandNotificationsActions";
import { Middleware } from "@reduxjs/toolkit";
import { RootState } from "types/state.type";

const upsertBrandNotificationHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (upsertBrandNotification.match(action)) {
      const state: RootState = getState();
      const { notification, brandId } = action.payload;
      const exists = state.brandNotifications[brandId]?.find((n) => n === notification);
      if (!exists) {
        const newNotifications = [...(state.brandNotifications[brandId] ?? []), notification];
        dispatch(setBrandNotification({ brandId, notifications: newNotifications }));
      }
    }
  };

const removeBrandNotificationHandler: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (removeBrandNotification.match(action)) {
      const state: RootState = getState();
      const { notification, brandId } = action.payload;
      const exists = state.brandNotifications[brandId]?.find((n) => n === notification);
      if (exists) {
        const newNotifications = state.brandNotifications[brandId]?.filter(
          (n) => n !== notification
        );
        dispatch(setBrandNotification({ brandId, notifications: newNotifications }));
      }
    }
  };

export const brandNotificationsMiddleware = [
  upsertBrandNotificationHandler,
  removeBrandNotificationHandler,
];
