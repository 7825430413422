import React, { useCallback, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";

export const hoursPerSlot = 0.5;
export const startHour = 0;
export const endHour = 24;
export const numberOfRows = (endHour - startHour) / hoursPerSlot;

function getStartDayFromLocalStorage(brandId: string) {
  return localStorage.getItem(`start-day-${brandId}`);
}

function setOrRemoveStartDayFromLocalStorage(brandId: string, startDay?: string) {
  if (startDay) {
    localStorage.setItem(
      `start-day-${brandId}`,
      DateTime.fromISO(startDay, { setZone: true }).toFormat("yyyy-MM-dd")
    );
  } else {
    localStorage.removeItem(`start-day-${brandId}`);
  }
}

function validateInitialStartDay(startDay: string | null, timeZone?: string) {
  if (!startDay) return undefined;
  const startDateTime = DateTime.fromFormat(startDay, "yyyy-MM-dd", {
    zone: timeZone,
  });
  return startDateTime.isValid && startDateTime.weekday === 1 ? startDateTime.toISO() : undefined;
}

interface UseCalendarProps {
  timeZone?: string;
}

export function useCalendar(props: UseCalendarProps) {
  const timeZone = props?.timeZone;
  const [searchParams, setSearchParams] = useSearchParams();
  const savedInitialStartDayToLocalStorage = React.useRef(false);
  const grabbedStartDayFromLocalStorage = React.useRef(false);
  const { brandId } = useParams<{ brandId: string }>();
  if (!brandId) {
    throw new Error("BrandPage id is required");
  }
  const startDay = useMemo(() => {
    if (!timeZone) return null;
    const startDayFromQuery = validateInitialStartDay(searchParams.get("start-day"), timeZone);
    if (startDayFromQuery) return startDayFromQuery;
    const startDayFromLocalStorage = validateInitialStartDay(
      getStartDayFromLocalStorage(brandId),
      timeZone
    );
    if (startDayFromLocalStorage) return startDayFromLocalStorage;
    return (
      timeZone &&
      DateTime.fromObject(
        { weekday: 1, hour: 0, minute: 0, second: 0, millisecond: 0 },
        { zone: timeZone }
      ).toISO()
    );
  }, [brandId, searchParams, timeZone]);

  const endDay = useMemo(
    () => startDay && DateTime.fromISO(startDay, { setZone: true }).plus({ days: 6 }).toISO(),
    [startDay]
  );

  const saveStartDayToSearchParams = React.useCallback(
    (startDay?: string) => {
      if (startDay) {
        setSearchParams((s) => {
          s.set("start-day", DateTime.fromISO(startDay, { setZone: true }).toFormat("yyyy-MM-dd"));
          return s;
        });
      } else {
        setSearchParams((s) => {
          s.delete("start-day");
          return s;
        });
      }
    },
    [setSearchParams]
  );

  const days = useMemo(() => {
    if (!startDay) return null;
    if (!timeZone) return null;
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(DateTime.fromISO(startDay, { setZone: true }).plus({ days: i }).toISO());
    }
    if (days.some((day) => day === null)) return null;
    return days as string[];
  }, [startDay, timeZone]);

  const slotsStartTimes = useMemo(() => {
    const firstSlotStart = DateTime.fromObject({ hour: startHour, minute: 0, second: 0 });
    const lastSlotStart = DateTime.fromObject(firstSlotStart.toObject()).plus({
      hours: endHour - hoursPerSlot,
    });
    const slotsStartTimes = [];
    let currentSlotStart = firstSlotStart;
    while (currentSlotStart <= lastSlotStart) {
      slotsStartTimes.push(currentSlotStart.toISOTime());
      currentSlotStart = currentSlotStart.plus({ hours: hoursPerSlot });
    }
    return slotsStartTimes as string[];
  }, []);

  const makeSlots = useCallback(
    (days: string[], startDay?: string, endDay?: string, timeZone?: string) => {
      if (!startDay || !endDay) return null;
      if (!slotsStartTimes) return null;
      if (!timeZone) return null;
      const slots = [];
      for (const slotStartTime of slotsStartTimes) {
        for (const day of days) {
          slots.push({
            start: DateTime.fromISO(day, { setZone: true })
              .set({
                hour: DateTime.fromISO(slotStartTime, { setZone: true }).hour,
                minute: DateTime.fromISO(slotStartTime, { setZone: true }).minute,
              })
              .setZone(timeZone)
              .toISO(),
            end: DateTime.fromISO(day, { setZone: true })
              .set({
                hour: DateTime.fromISO(slotStartTime, { setZone: true }).hour,
                minute: DateTime.fromISO(slotStartTime, { setZone: true }).minute,
              })
              .setZone(timeZone)
              .plus({ hours: hoursPerSlot })
              .toISO(),
          });
        }
      }
      return slots as { start: string; end: string }[];
    },
    [slotsStartTimes]
  );

  const slots = useMemo(() => {
    if (!startDay || !days || !endDay || !timeZone) return null;
    return days ? makeSlots(days, startDay, endDay, timeZone) : null;
  }, [days, endDay, makeSlots, startDay, timeZone]);

  React.useEffect(() => {
    // on mount - if no start day in search params - set start day from local storage
    if (grabbedStartDayFromLocalStorage.current) return;
    grabbedStartDayFromLocalStorage.current = true;
    const startDayFromQuery = searchParams.get("start-day");
    if (!startDayFromQuery) {
      const startDayFromLocalStorage = getStartDayFromLocalStorage(brandId!);
      const validateDateValue = validateInitialStartDay(startDayFromLocalStorage);
      if (!validateDateValue) return;
      if (startDayFromLocalStorage) {
        saveStartDayToSearchParams(validateDateValue);
      }
    }
  }, [brandId, saveStartDayToSearchParams, searchParams, setSearchParams]);

  React.useEffect(() => {
    // save start day to local storage on mount
    if (savedInitialStartDayToLocalStorage.current) return;
    const startDayFromQuery = searchParams.get("start-day");
    if (!startDayFromQuery) return;
    const validatedDateValue = validateInitialStartDay(startDayFromQuery);
    if (!validatedDateValue) {
      // if not valid - remove from local storage
      setSearchParams((s) => {
        s.delete("start-day");
        return s;
      });
      return;
    }
    // if valid - save to local storage
    savedInitialStartDayToLocalStorage.current = true;
    queueMicrotask(() => setOrRemoveStartDayFromLocalStorage(brandId!, validatedDateValue));
  }, [setSearchParams, saveStartDayToSearchParams, searchParams, brandId]);

  async function goToNextWeek() {
    if (!endDay || !startDay) return;
    const startDayToSet = DateTime.fromISO(endDay, { setZone: true }).plus({ days: 1 }).toISO();
    if (!startDayToSet) return;
    saveStartDayToSearchParams(startDayToSet);
    queueMicrotask(() => setOrRemoveStartDayFromLocalStorage(brandId!, startDayToSet));
  }

  async function goToPreviousWeek() {
    if (!startDay) return;
    const startDayToSet = DateTime.fromISO(startDay, { setZone: true }).minus({ days: 7 }).toISO();
    if (!startDayToSet) return;
    saveStartDayToSearchParams(startDayToSet);
    queueMicrotask(() => setOrRemoveStartDayFromLocalStorage(brandId!, startDayToSet));
  }

  async function goToCurrentWeek() {
    setOrRemoveStartDayFromLocalStorage(brandId!);
    saveStartDayToSearchParams();
    queueMicrotask(() => setOrRemoveStartDayFromLocalStorage(brandId!));
  }

  const showingCurrentWeek = useMemo(() => {
    if (!startDay || !endDay) return null;
    if (!timeZone) return null;
    const startOfCurrentWeek = DateTime.now().setZone(timeZone).startOf("week").toISO();
    return startDay === startOfCurrentWeek;
  }, [startDay, endDay, timeZone]);

  return {
    showingCurrentWeek,
    days,
    slots,
    goToNextWeek,
    goToPreviousWeek,
    slotsStartTimes,
    goToCurrentWeek,
  };
}
