export const replaceAllObjKeys = <T, I>(obj: I, getNewKey: (key: string) => string): T => {
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      replaceAllObjKeys(obj[i], getNewKey);
    }
  } else if (typeof obj === "object") {
    for (const key in obj) {
      const newKey = getNewKey(key);
      if (key !== newKey) {
        obj[newKey as keyof typeof obj] = obj[key as keyof typeof obj];
        delete obj[key];
      }
      replaceAllObjKeys(obj[newKey as keyof typeof obj], getNewKey);
    }
  }

  return obj as unknown as T;
};
