import { SLICES } from "types/slices.type";
import authSlice from "state/features/auth/authSlice";
import cofigSlice from "state/features/config/configSlice";
import historySlice from "state/features/history/historySlice";
import brandNotificationsSlice from "state/features/brandNotifications/brandNotificationsSlice";
import userNotificationsSlice from "state/features/userNotifications/userNotificationsSlice";

const sliceReducer = {
  [SLICES.AUTH]: authSlice.reducer,
  [SLICES.CONFIG]: cofigSlice.reducer,
  [SLICES.HISTORY]: historySlice.reducer,
  [SLICES.BRAND_NOTIFICATIONS]: brandNotificationsSlice.reducer,
  [SLICES.USER_NOTIFICATIONS]: userNotificationsSlice.reducer,
};

export { sliceReducer };
