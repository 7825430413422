import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ConfigState {
  apiBaseUrl?: string;
  staticAssetsUrl?: string;
  loginUrl?: string;
  cognitoClientId?: string;
  cognitoUserPoolId?: string;
  adminOrganizationId?: string[];
  facebookTesterOrganizationId?: string;
  homepageUrl?: string;
  mixpanelToken?: string;
  cbSite?: string;
  plansProps?: string;
  chargebeeCheckoutUrl?: string;
  chargebeeCheckoutOrigin?: string;
  chargebeeSelfServePortalUrl?: string;
  environment?: "development" | "staging" | "production" | "test";
  status: "uninitialized" | "initializing" | "ready" | "error" | "refreshing";
  chaosEnabled?: boolean;
  chaosUnlikelihood: number;
}

const initialConfigState: ConfigState = {
  status: "uninitialized",
  chaosUnlikelihood: 6,
};

const configSlice = createSlice({
  initialState: initialConfigState,
  name: "config",
  reducers: {
    setConfigApiBaseUrl: (state, action: PayloadAction<string | undefined>) => {
      state.apiBaseUrl = action.payload;
    },
    setConfigLoginUrl: (state, action: PayloadAction<string | undefined>) => {
      state.loginUrl = action.payload;
    },
    setConfigStatus: (state, action: PayloadAction<ConfigState["status"]>) => {
      state.status = action.payload;
    },
    setConfigCognitoClientId: (state, action: PayloadAction<string | undefined>) => {
      state.cognitoClientId = action.payload;
    },
    setConfigCognitoUserPoolId: (state, action: PayloadAction<string | undefined>) => {
      state.cognitoUserPoolId = action.payload;
    },
    setConfigAdminOrganizationId: (state, action: PayloadAction<string[] | undefined>) => {
      state.adminOrganizationId = action.payload;
    },
    setConfigMixpanelToken: (state, action: PayloadAction<string | undefined>) => {
      state.mixpanelToken = action.payload;
    },
    setConfigEnvironment: (state, action: PayloadAction<ConfigState["environment"]>) => {
      state.environment = action.payload;
    },
    setConfigStaticAssetsUrl: (state, action: PayloadAction<string | undefined>) => {
      state.staticAssetsUrl = action.payload;
    },
    setConfigHomepageUrl: (state, action: PayloadAction<string | undefined>) => {
      state.homepageUrl = action.payload;
    },
    setConfigCbSite: (state, action: PayloadAction<string | undefined>) => {
      state.cbSite = action.payload;
    },
    setConfigPlansProps: (state, action: PayloadAction<string | undefined>) => {
      state.plansProps = action.payload;
    },
    setConfigFacebookTesterOrganizationId: (state, action: PayloadAction<string | undefined>) => {
      state.facebookTesterOrganizationId = action.payload;
    },
    setConfigChargebeeCheckoutUrl: (state, action: PayloadAction<string | undefined>) => {
      state.chargebeeCheckoutUrl = action.payload;
    },
    setConfigChargebeeSelfServePortalUrl: (state, action: PayloadAction<string | undefined>) => {
      state.chargebeeSelfServePortalUrl = action.payload;
    },
    setConfigChargebeeCheckoutOrigin: (state, action: PayloadAction<string | undefined>) => {
      state.chargebeeCheckoutOrigin = action.payload;
    },
    setConfigChaosEnabled: (state, action: PayloadAction<boolean | undefined>) => {
      state.chaosEnabled = action.payload;
    },
    setConfigChaosUnlikelihood: (state, action: PayloadAction<number>) => {
      state.chaosUnlikelihood = action.payload;
    },
  },
});

export default configSlice;
