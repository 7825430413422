import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  idToken?: string;
  email?: string;
  organizationId?: string;
  sub?: string;
  name?: string;
  workspaceDomain?: string;
  expiresAt?: number;
  redirectToPage?: string;
  isAdmin?: boolean;
}

const initialAuthState: AuthState = {};

const authSlice = createSlice({
  initialState: initialAuthState,
  name: "auth",
  reducers: {
    setIdToken: (state, action: PayloadAction<string | undefined>) => {
      state.idToken = action.payload;
    },
    setOrganizationId: (state, action: PayloadAction<string | undefined>) => {
      state.organizationId = action.payload;
    },
    setSub: (state, action: PayloadAction<string | undefined>) => {
      state.sub = action.payload;
    },
    setEmail: (state, action: PayloadAction<string | undefined>) => {
      state.email = action.payload;
    },
    setName: (state, action: PayloadAction<string | undefined>) => {
      state.name = action.payload;
    },
    setWorkspaceDomain: (state, action: PayloadAction<string | undefined>) => {
      state.workspaceDomain = action.payload;
    },
    setExpiresAt: (state, action: PayloadAction<number | undefined>) => {
      state.expiresAt = action.payload;
    },
    setIsAdmin: (state, action: PayloadAction<boolean | undefined>) => {
      state.isAdmin = action.payload;
    },
    setRedirectToPage: (state, action: PayloadAction<string | undefined>) => {
      state.redirectToPage = action.payload;
    },
  },
});

export default authSlice;
