import { AppText, TranslateProps } from "components/AppText/AppText";
import React from "react";
import tw from "tailwind-styled-components";

const TextElementStyles = {
  BodyText: {
    XLarge: tw.span`text-[16px] leading-6`,
    Large: tw.span`text-[14px] leading-6`,
    Medium: tw.span`font-normal text-[12px] leading-6`,
    Small: tw.span`font-normal text-[10px] leading-6`,
    Basic: tw.span`text-[20px]`,
  },
  HeadTitle: {
    Large: tw.span`text-[36px] leading-[46px] font-medium`,
    Medium: tw.span`text-[24px] leading-[28px] font-medium`,
    Small: tw.span`text-[22px] leading-[26px] font-medium`,
  },
  SubTitleText: {
    XLarge: tw.span`font-medium text-[20px] leading-6`,
    Large: tw.span`font-medium text-[16px] leading-6`,
    Medium: tw.span`font-medium text-[14px] leading-6`,
    Small: tw.span`font-medium text-[12px] leading-6`,
  },
};

export const TextElement: React.FC<
  ElementStyleProps<string> & { type: keyof typeof TextElementStyles }
> = ({ className, element, translationKey, text, vars, children, type }) => {
  const Element =
    TextElementStyles[type][element as keyof (typeof TextElementStyles)[typeof type]] || tw.span``;
  return (
    <Element className={className} dir="auto">
      <AppText translationKey={translationKey} text={text} vars={vars}>
        {children}
      </AppText>
    </Element>
  );
};

export interface ElementStyleProps<T> extends TranslateProps {
  className?: string;
  element: T;
}

type BodyText = `${BodyTextSize}`;
type HeadTitle = `${HeadTitleSize}`;
type SubTitleText = `${SubTitleTextSize}`;

enum BodyTextSize {
  XLarge = "XLarge",
  Large = "Large",
  Medium = "Medium",
  Small = "Small",
  Basic = "Basic",
}

enum HeadTitleSize {
  Large = "Large",
  Medium = "Medium",
  Small = "Small",
}

enum SubTitleTextSize {
  XLarge = "XLarge",
  Large = "Large",
  Medium = "Medium",
  Small = "Small",
}

export const BodyText: React.FC<ElementStyleProps<BodyText>> = (props) => (
  <TextElement {...props} type="BodyText" />
);

export const HeadTitle: React.FC<ElementStyleProps<HeadTitle>> = (props) => (
  <TextElement {...props} type="HeadTitle" />
);

export const SubTitleText: React.FC<ElementStyleProps<SubTitleText>> = (props) => (
  <TextElement {...props} type="SubTitleText" />
);
