import { BrandCalendarSlotOverridesUpdatedEvent } from "types/contentCalendarSchedule.type";
import { replaceAllObjKeys } from "utils/trasnformResponseFromApi/replaceAllObjKeys";

const transformBrandCalendarSlotOverridesUpdatedEventKeys: Record<string, string> = {
  post_id: "postId",
  posting_date: "postingDate",
  platform: "platform",
  image_url: "imageUrl",
  overrides: "overrides",
  text: "text",
};

function fromResponse(
  response: Record<keyof typeof transformBrandCalendarSlotOverridesUpdatedEventKeys, unknown>
): BrandCalendarSlotOverridesUpdatedEvent {
  return replaceAllObjKeys<
    BrandCalendarSlotOverridesUpdatedEvent,
    Record<keyof typeof transformBrandCalendarSlotOverridesUpdatedEventKeys, unknown>
  >(response, (key) => {
    const newKey = transformBrandCalendarSlotOverridesUpdatedEventKeys[key];
    return newKey || key;
  });
}

function toResponse(
  brandCalendarSlotOverridesUpdatedEvent: Partial<BrandCalendarSlotOverridesUpdatedEvent>
): Record<string, unknown> {
  return replaceAllObjKeys<
    Record<string, unknown>,
    Partial<BrandCalendarSlotOverridesUpdatedEvent>
  >(brandCalendarSlotOverridesUpdatedEvent, (key) => {
    const newKey = Object.keys(transformBrandCalendarSlotOverridesUpdatedEventKeys).find(
      (k) => transformBrandCalendarSlotOverridesUpdatedEventKeys[k] === key
    );
    return newKey || key;
  });
}

export default { fromResponse, toResponse };
