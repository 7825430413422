import { authService } from "api/services/auth/authService";
import { APIS } from "types/api.type";
import { brandService } from "api/services/brand/brand.service";
import { scraperService } from "api/services/newBrand/scraperService";
import { integrationsService } from "api/services/integrations/integrations.service";
import { subscriptionService } from "api/services/subscription/subscription.service";
import { contentService } from "api/services/content/content.service";

const apisReducer = {
  [APIS.AUTH]: authService.reducer,
  [APIS.BRAND]: brandService.reducer,
  [APIS.SUBSCRIPTION]: subscriptionService.reducer,
  [APIS.CONTENT]: contentService.reducer,
  [APIS.SCRAPER]: scraperService.reducer,
  [APIS.INTEGRATIONS]: integrationsService.reducer,
};

const apisMiddleware = [
  authService.middleware,
  brandService.middleware,
  contentService.middleware,
  scraperService.middleware,
  subscriptionService.middleware,
  integrationsService.middleware,
];

export { apisMiddleware, apisReducer };
