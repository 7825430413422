import tw from "tailwind-styled-components";
import { FC, useMemo } from "react";
import { SubTitleText } from "components/TextElement/TextElement";

export interface BrandIconProps extends React.HTMLAttributes<HTMLDivElement> {
  imageUrl?: string;
  brandName: string;
  children?: React.ReactNode | React.ReactNode[];
  size?: number;
}

export const BrandIcon: FC<BrandIconProps> = ({
  children,
  imageUrl,
  brandName,
  size = 50,
  ...props
}) => {
  const brandNameAcronym = useMemo(() => {
    return brandName
      .split(" ")
      .splice(0, 2)
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  }, [brandName]);

  const parsedImageUrl = (() => {
    if (!imageUrl) return;
    const parsedUrl = new URL(imageUrl);
    const encodedUrl = parsedUrl.pathname.split("/").map(encodeURIComponent).join("/");
    return `${parsedUrl.origin}${encodedUrl}?width=${size}&height=${size}`;
  })();

  return (
    <IconContainer $withBackground={!imageUrl} {...props} style={{ height: size, width: size }}>
      {imageUrl ? (
        <img
          src={parsedImageUrl}
          alt={brandName}
          className="object-cover rounded-lg"
          height={size}
          width={size}
        />
      ) : (
        <SubTitleText
          element="Large"
          className="text-white font-semibold"
          text={brandNameAcronym}
        />
      )}
      {children}
    </IconContainer>
  );
};
const IconContainer = tw.div<{ $withBackground?: boolean; $withBorder?: boolean }>`
  ${({ $withBackground }) => ($withBackground ? "bg-secondary" : "bg-white")}
  rounded-lg
  drop-shadow-sm
  shadow-md
  object-scale-down
  border-primaryGreen-100
  flex 
  justify-center
  items-center
  aspect-square
`;
