import React from "react";
import { ClipLoader } from "react-spinners";
import { motion } from "framer-motion";

interface LoaderProps {
  size?: number;
  color?: string;
}

export const Loader = ({ size = 50, color = "#57576F" }: LoaderProps) => {
  return (
    <motion.div
      className="flex justify-center items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.2, type: "tween", duration: 0.5 } }}
      exit={{ opacity: 0 }}
    >
      <ClipLoader color={color} size={size} />
    </motion.div>
  );
};
