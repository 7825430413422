import { createSlice } from "@reduxjs/toolkit";

export enum UserNotification {
  PLAN_EXPIRED = "plan_expired",
  RETURNING_USER_WAITING_FOR_NEXT_SCHEDULER = "returning_user_waiting_for_next_scheduler",
}
export type UserNotificationsState = {
  [userSub: string]: UserNotification[];
};

const initialState: UserNotificationsState = {};

const UserNotificationsSlice = createSlice({
  initialState,
  name: "userNotifications",
  reducers: {
    setUserNotifications: (
      state,
      action: {
        payload: { userSub: string; notifications: UserNotification[] };
      }
    ) => {
      state[action.payload.userSub] = action.payload.notifications;
    },
  },
});

export default UserNotificationsSlice;
