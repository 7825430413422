import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "router/AppRouter";
import { persistor, store } from "state/store";
import { CustomErrorBoundary } from "components/ErrorBoundary/CustomErrorBoundary";
import { WithAnalytics } from "context/withAnalytics/WithAnalytics";
import { WithConfig } from "context/withConfig/WithConfig";
import { WithFonts } from "context/withFonts/WithFonts";
import { ToastContainer } from "react-toastify";
import { WithLocale } from "context/withLocale/WithLocale";

const App = () => {
  return (
    <CustomErrorBoundary>
      <Provider store={store}>
        <WithConfig>
          <WithAnalytics>
            <WithLocale>
              <WithFonts>
                <ToastContainer className="!min-w-fit" />
                <PersistGate persistor={persistor}>
                  <BrowserRouter>
                    <AppRouter />
                  </BrowserRouter>
                </PersistGate>
              </WithFonts>
            </WithLocale>
          </WithAnalytics>
        </WithConfig>
      </Provider>
    </CustomErrorBoundary>
  );
};

export default App;
