import { createAction } from "@reduxjs/toolkit";
import { IntegrationProvider } from "types/integrationProviders.type";
import { AnalyticsEvent } from "types/analyticsEvent.type";
import { Brand } from "types/brand.type";
import { BrandCalendarSlotOverridesUpdatedEvent } from "types/contentCalendarSchedule.type";

//CLIENT ROUTER
export const trackClientRouterPathChanged = createAction<
  { path: string },
  "analytics/trackClientRouterPathChanged"
>("analytics/trackClientRouterPathChanged");

// API ERRORS
export const trackRejectedApiQuery = createAction<
  {
    action: JSON;
  },
  "analytics/trackRejectedApiQuery"
>("analytics/trackRejectedApiQuery");
export const trackRejectedApiMutation = createAction<
  {
    action: JSON;
  },
  "analytics/trackRejectedApiMutation"
>("analytics/trackRejectedApiMutation");

// STATIC ASSETS EVENTS
export const trackStaticAssetsFontsInitializeStart = createAction<
  undefined,
  "analytics/trackStaticAssetsFontsInitializeStart"
>("analytics/trackStaticAssetsFontsInitializeStart");
export const trackStaticAssetsFontsInitializeEnd = createAction<
  { status: "rejected" | "resolved"; error?: string },
  "analytics/trackStaticAssetsFontsInitializeEnd"
>("analytics/trackStaticAssetsFontsInitializeEnd");
export const trackStaticAssetsLocaleNamespaceLoadingFailed = createAction<
  { namespace: string; error: string; language: string },
  "analytics/trackStaticAssetsNamespaceLoadingFailed"
>("analytics/trackStaticAssetsNamespaceLoadingFailed");

// CONTENT SLOT UPDATED EVENTS
export const trackContentSlotUpdatedStart = createAction<
  {
    postId: string;
    brandId: string;
    postingDate: string;
    platform: string;
    approvedToPost: boolean | null;
  },
  "analytics/trackContentSlotUpdatedStart"
>("analytics/trackContentSlotUpdatedStart");
export const trackContentSlotUpdatedEnd = createAction<
  {
    postId: string;
    brandId: string;
    postingDate: string;
    platform: string;
    approvedToPost: boolean | null;
    status: "rejected" | "resolved";
  },
  "analytics/trackContentSlotUpdatedEnd"
>("analytics/trackContentSlotUpdatedEnd");

//JOIN WAITLIST EVENTS
export const trackJoinWaitingList = createAction<{ brandId?: string }>(
  "analytics/trackJoinWaitingList"
);

// CHARGEBEE EVENTS
export const trackRedirectToChargebeeCheckout = createAction<{
  PlanItemId: string;
  redirectTo: string;
  brandId: string;
}>("analytics/trackChargebeeRedirectToCheckout");

export const trackRedirectToChargebeeSelfServePortal = createAction<{
  redirectTo: string;
  brandId: string;
}>("analytics/trackChargebeeRedirectToSelfServePortal");

export const trackChargebeeSelfServePortalLoaded = createAction<{
  brandId: string;
}>("analytics/trackChargebeeSelfServePortalLoaded");

export const trackChargebeeSelfServePortalPageVisited = createAction<{
  brandId: string;
  messageData: JSON;
}>("analytics/trackChargebeeSelfServePageVisited");

export const trackChargebeeSelfServePortalSubscriptionReactivated = createAction<{
  brandId: string;
  messageData: JSON;
}>("analytics/trackChargebeeSelfServePortalSubscriptionReactivated");

export const trackChargebeeSelfServePortalSubscriptionCancelled = createAction<{
  brandId: string;
  messageData: JSON;
}>("analytics/trackChargebeeSelfServePortalSubscriptionCancelled");

export const trackCheckoutCallbackReceived = createAction<{
  status: "rejected" | "resolved";
  subscriptionId?: string;
  invoiceId?: string;
  brandId: string;
}>("analytics/trackCheckoutCallbackReceived");

export const trackChargebeeCheckoutLoaded = createAction<{
  brandId: string;
}>("analytics/trackChargebeeCheckoutLoaded");

export const trackChargebeeCheckoutPageVisited = createAction<{
  brandId: string;
  messageData: JSON;
}>("analytics/trackChargebeeCheckoutPageVisited");

export const trackChargebeeCheckoutSetHpToken = createAction<{
  brandId: string;
  messageData: JSON;
}>("analytics/trackChargebeeCheckoutSetHpToken");

//UPDATE BRAND EVENTS
export const trackBrandEdited = createAction<
  { brandId?: string; field: string },
  "analytics/trackBrandEdited"
>("analytics/trackBrandEdited");
export const trackBrandUpdatedStart = createAction<
  { brandId?: string; updatedBrand: Partial<Brand> },
  "analytics/trackBrandUpdatedStart"
>("analytics/trackBrandUpdatedStart");
export const trackBrandUpdatedEnd = createAction<
  {
    brandId?: string;
    updatedBrand: Partial<Brand>;
    status: "rejected" | "resolved";
  },
  "analytics/trackBrandUpdatedEnd"
>("analytics/trackBrandUpdatedEnd");

//POST IMAGE EVENTS
export const trackPostImagePresignStart = createAction<
  { brandId?: string; fileType: string; postId: string; postingDate: string; platform: string },
  "analytics/trackPostImagePresignStart"
>("analytics/trackPostImagePresignStart");
export const trackPostImagePresignEnd = createAction<
  {
    brandId?: string;
    fileType: string;
    status: "rejected" | "resolved";
    postId: string;
    postingDate: string;
    platform: string;
    postImageUrl?: string;
    presignedUrl?: string;
  },
  "analytics/trackPostImagePresignEnd"
>("analytics/trackPostImagePresignEnd");
export const trackPostImageUploadedStart = createAction<
  {
    brandId?: string;
    presignedUrl: string;
    postId: string;
    postingDate: string;
    platform: string;
    postImageUrl: string;
  },
  "analytics/trackPostImageUploadedStart"
>("analytics/trackPostImageUploadedStart");
export const trackPostImageUploadedEnd = createAction<
  {
    brandId?: string;
    presignedUrl: string;
    status: "rejected" | "resolved";
    postId: string;
    postingDate: string;
    platform: string;
  },
  "analytics/trackPostImageUploadedEnd"
>("analytics/trackPostImageUploadedEnd");

export const trackRequestedEditPostText = createAction<
  { postId: string; brandId: string; platform: string; postingDate: string },
  "analytics/trackRequestedEditPostText"
>("analytics/trackRequestedEditPostText");

//BRAND CALENDAR SLOT OVERRIDES UPDATED EVENT
export const trackBrandCalendarSlotOverridesUpdatedStart = createAction<
  BrandCalendarSlotOverridesUpdatedEvent & {
    brandId?: string;
  },
  "analytics/trackBrandCalendarSlotOverridesUpdatedStart"
>("analytics/trackBrandCalendarSlotOverridesUpdatedStart");
export const trackBrandCalendarSlotOverridesUpdatedEnd = createAction<
  BrandCalendarSlotOverridesUpdatedEvent & {
    brandId?: string;
    status: "rejected" | "resolved";
  },
  "analytics/trackBrandCalendarSlotOverridesUpdatedEnd"
>("analytics/trackBrandCalendarSlotOverridesUpdatedEnd");

//BRAND ICON EVENTS
export const trackBrandIconPresignStart = createAction<
  { brandId?: string; fileType: string },
  "analytics/trackBrandIconPresignStart"
>("analytics/trackBrandIconPresignStart");
export const trackBrandIconPresignEnd = createAction<
  { brandId?: string; fileType: string; status: "rejected" | "resolved" },
  "analytics/trackBrandIconPresignEnd"
>("analytics/trackBrandIconPresignEnd");
export const trackBrandIconUploadedStart = createAction<
  { brandId?: string; presignedUrl: string },
  "analytics/trackBrandLogoUploadedStart"
>("analytics/trackBrandLogoUploadedStart");
export const trackBrandIconUploadedEnd = createAction<
  { brandId?: string; presignedUrl: string; status: "rejected" | "resolved" },
  "analytics/trackBrandLogoUploadedEnd"
>("analytics/trackBrandLogoUploadedEnd");

//WEBSITE LANGUAGE EVENTS
export const trackLocaleRequestedStart = createAction<
  { locale: string },
  "analytics/trackLocaleRequestedStart"
>("analytics/trackLocaleRequestedStart");

export const trackLocaleRequestedEnd = createAction<
  { locale: string; status: "rejected" | "resolved" },
  "analytics/trackLocaleRequestedEnd"
>("analytics/trackLocaleRequestedEnd");

export const trackWebsiteLanguageUpdated = createAction<
  { locale: string },
  "analytics/trackWebsiteLanguageUpdated"
>("analytics/trackWebsiteLanguageUpdated");

export const trackPublishNow = createAction<
  {
    postId: string;
    brandId: string;
    postingDate: string;
    platform: string;
    approvedToPost: boolean | null;
  },
  "analytics/trackPublishNow"
>("analytics/trackPublishNow");

// CALENDAR EVENTS
export const trackPostViewed = createAction<
  {
    postId: string;
    brandId: string;
    postingDate: string;
    platform: string;
    approvedToPost: boolean | null;
    lockedByScheduler: boolean;
    successfullyPosted: boolean | null;
  },
  "analytics/trackPostViewed"
>("analytics/trackPostViewed");

export const trackPostBlurred = createAction<
  {
    postId: string;
    brandId: string;
    postingDate: string;
    platform: string;
    approvedToPost: boolean | null;
    lockedByScheduler: boolean;
    successfullyPosted: boolean | null;
  },
  "analytics/trackPostBlurred"
>("analytics/trackPostBlurred");

// AUTH EVENTS
export const trackRedirectToLoginPage = createAction<
  undefined,
  "analytics/trackRedirectToLoginPage"
>("analytics/trackRedirectToLoginPage");
export const trackLoginCallbackStart = createAction<
  { idTokenIsPresent: boolean },
  "analytics/trackLoginCallbackStart"
>("analytics/trackLoginCallbackStart");
export const trackLoginCallbackEnd = createAction<
  { redirectedToPath?: string; status: "rejected" | "resolved" },
  "analytics/trackLoginCallbackEnd"
>("analytics/trackLoginCallbackEnd");
export const trackLoginIdTokenVerifiedStart = createAction<
  { cognitoClientId: string; cognitoUserPoolId: string },
  "analytics/trackLoginIdTokenVerifiedStart"
>("analytics/trackLoginIdTokenVerifiedStart");
export const trackLoginIdTokenVerifiedEnd = createAction<
  { status: "rejected" | "resolved"; isAdmin?: boolean; error?: string },
  "analytics/trackLoginIdTokenVerifiedEnd"
>("analytics/trackLoginIdTokenVerifiedEnd");
export const trackLogoutStart = createAction<
  { sub: string; organizationId: string },
  "analytics/trackLogoutStart"
>("analytics/trackLogoutStart");
export const trackLogoutEnd = createAction<
  { sub: string; organizationId: string; status: "rejected" | "resolved" },
  "analytics/trackLogoutEnd"
>("analytics/trackLogoutEnd");

// BRAND ADDED EVENTS
export const trackBrandAddedStart = createAction<
  { brandName: string },
  "analytics/trackBrandAddedStart"
>("analytics/trackBrandAddedStart");
export const trackBrandAddedEnd = createAction<
  { brandName: string; brandId?: string; status: "rejected" | "resolved"; organizationId: string },
  "analytics/trackBrandAddedEnd"
>("analytics/trackBrandAddedEnd");

// SCRAPER EVENTS
export const trackScrapedHomepageDetailsStart = createAction<
  { brandId?: string; homepageUrl: string; organizationId: string },
  "analytics/scrapedHomepageDetailsStart"
>("analytics/scrapedHomepageDetailsStart");
export const trackScrapedHomepageDetailsEnd = createAction<
  {
    brandId?: string;
    homepageUrl: string;
    status: "rejected" | "resolved";
    organizationId: string;
    detectedSiteType?: string;
    detectedLanguage?: string;
  },
  "analytics/scrapedHomepageDetailsEnd"
>("analytics/scrapedHomepageDetailsEnd");

// REVIEWED SCRAPER EVENTS
export const trackApprovedScrapedHomepageDetailsStart = createAction<
  { brandId?: string; homepageUrl: string; organizationId: string },
  "analytics/approvedScrapedHomepageDetailsStart"
>("analytics/approvedScrapedHomepageDetailsStart");
export const trackApprovedScrapedHomepageDetailsEnd = createAction<
  {
    brandId?: string;
    homepageUrl: string;
    status: "rejected" | "resolved";
    organizationId: string;
  },
  "analytics/approvedScrapedHomepageDetailsEnd"
>("analytics/approvedScrapedHomepageDetailsEnd");
export const trackEditedScrapedHomepageDetails = createAction<{
  brandId?: string;
  field: string;
}>("analytics/editedScrapedHomepageDetails");

// OAUTH CONNECT EVENTS
export const trackOauthConnectStart = createAction<{
  provider: IntegrationProvider;
  brandId: string;
}>("analytics/trackOauthConnectStart");
export const trackOauthConnectEnd = createAction<{
  provider: IntegrationProvider;
  status: "rejected" | "resolved";
  brandId: string;
}>("analytics/trackOauthConnectEnd");
export const trackOauthConnectRedirect = createAction<{
  provider: IntegrationProvider;
  brandId: string;
}>("analytics/trackOauthConnectRedirect");
export const trackOauthProviderPostingPageSelectionStart = createAction<{
  provider: IntegrationProvider;
  brandId: string;
}>("analytics/trackOauthProviderPostingPageSelectionStart");
export const trackOauthProviderPostingPageSelectionEnd = createAction<{
  provider: IntegrationProvider;
  status: "rejected" | "resolved";
  brandId: string;
}>("analytics/trackOauthProviderPostingPageSelectionEnd");
export const trackPostingPagesListStart = createAction<{
  provider: IntegrationProvider;
  brandId: string;
}>("analytics/trackPagesListStart");
export const trackPostingPagesListEnd = createAction<{
  provider: IntegrationProvider;
  status: "rejected" | "resolved";
  brandId: string;
}>("analytics/trackPagesListEnd");
// IDENTIFY USER
export const identifyUser = createAction<{ userIdentifier: string }, "analytics/identifyUser">(
  "analytics/identifyUser"
);
export const resetUser = createAction<undefined, "analytics/resetUser">("analytics/resetUser");

// BASE TRACK
export const baseTrack = createAction<AnalyticsEvent, "analytics/baseTrack">("analytics/baseTrack");
