import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const languageDetector = new LanguageDetector();
i18n
  .use(Backend)
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    backend: {
      loadPath: `${import.meta.env.VITE_STATIC_ASSETS_URL}/locale/{{lng}}/{{ns}}.json`,
    },
    ns: [
      "dates",
      "provider",
      "navbar",
      "integrations",
      "weeklyCalendar",
      "brandSettings",
      "selectBrand",
      "miniScrape",
      "connectToOauthProvider",
      "login",
      "contentCalendar",
      "postConnectionToOauthProvider",
      "createBrand",
      "meta",
      "changeBrand",
      "language",
      "postsInMaking",
      "planExpired",
      "sessionExpired",
      "newUser",
      "autopilot",
      "postsAreReady",
      "onboardingProgressBar",
    ],
    fallbackLng: "default",
    lng: localStorage.getItem("i18nextLng") || "default",
    load: "languageOnly",
    debug: false,
    react: {
      useSuspense: true,
    },
  });

export default i18n;
