import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "style/index.css";
import App from "./App";
import { Loader } from "components/Loader/Loader";
import "../src/services/i18n/i18n.service";
import "overlayscrollbars/overlayscrollbars.css";

const appContainer = document.getElementById("root");
const appRoot = createRoot(appContainer!);
appRoot.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="h-screen w-screen flex justify-center items-center bg-primary">
          <Loader />
        </div>
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>
);
