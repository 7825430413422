import { useConfig } from "hooks/useConfig";
import mixpanel from "mixpanel-browser";

const initialized = { current: false };

export const WithAnalytics = ({ children }: { children: React.ReactNode }) => {
  const { config } = useConfig();
  if (!config.mixpanelToken) {
    console.error("Mixpanel token is not provided");
    return children;
  }
  if (initialized.current) return children;
  mixpanel.init(config.mixpanelToken, {
    debug: config.environment !== "production",
    track_pageview: true,
    persistence: "localStorage",
  });
  initialized.current = true;
  if (config.environment === "development") {
    // prevent from sending analytics in development
    mixpanel.disable();
    mixpanel.opt_out_tracking();
  } else {
    mixpanel.opt_in_tracking();
  }
  return children;
};
