import { Route, Routes } from "react-router-dom";
import { WithAuth } from "context/withAuth/WithAuth";
import { usePageViews } from "hooks/usePageView.hooks";
import AppLayout from "components/AppLayout/AppLayout";
import {
  AutopilotPage,
  BrandLayout,
  BrandMiniScrapePage,
  BrandNotFoundPage,
  BrandPage,
  BrandSettingsPage,
  BrandsPage,
  ChangeBrandPage,
  CheckoutCallbackPage,
  ConnectToOauthProviderLayout,
  ConnectToOauthProviderPage,
  ConnectToOauthRedirectPage,
  CreateBrandPage,
  LastVisitedBrandPage,
  LatestBrandPage,
  LoginCallbackPage,
  LoginPage,
  LogoutPage,
  NewUserPage,
  OnboardingConnectToOauthCallbackPage,
  OnboardingConnectToOauthFailurePage,
  OnboardingConnectToOauthSuccessPage,
  OnboardingLayout,
  OnboardingSelectPageToPostPage,
  PostConnectionToOauthProviderPage,
  RootPage,
  SelectBrandPage,
  SettingsConnectToOauthCallbackPage,
  SettingsConnectToOauthFailurePage,
  SettingsConnectToOauthSuccessPage,
  SettingsSelectPageToPostPage,
  SocialMediaWeeklyCalendar,
} from "router/lazyComponents";
import { WithOnboarding } from "context/withIntegrations/withOnboarding";
import { WithSubscription } from "context/withSubscription/withSubscription";

export const AppRouter = () => {
  usePageViews();
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="*" element={<RootPage />} />
        <Route element={<OnboardingLayout />}>
          <Route path="/new-user" element={<NewUserPage />} />
        </Route>
        <Route path="/oauth/callback" element={<LoginCallbackPage />} />
        <Route path="/oauth/login" element={<LoginPage />} />
        <Route path="/oauth/logout" element={<LogoutPage />} />
        <Route element={<WithAuth />}>
          <Route path="/brands">
            <Route index element={<BrandsPage />} />
            <Route path="select" element={<SelectBrandPage />} />
            <Route element={<OnboardingLayout />}>
              <Route path="create" element={<CreateBrandPage />} />
            </Route>
            <Route path="not-found" element={<BrandNotFoundPage />} />
            <Route path="last-visited" element={<LastVisitedBrandPage />} />
            <Route path="latest" element={<LatestBrandPage />} />
            <Route path=":brandId" element={<BrandLayout />}>
              <Route path="change" element={<ChangeBrandPage />} />
              <Route index element={<BrandPage />} />
              <Route element={<OnboardingLayout />}>
                <Route path="mini-scrape" element={<BrandMiniScrapePage />} />
                <Route path="autopilot" element={<AutopilotPage />} />
                <Route path="connect-to-oauth-provider">
                  <Route path="callback" element={<OnboardingConnectToOauthCallbackPage />} />
                  <Route element={<ConnectToOauthProviderLayout />}>
                    <Route element={<ConnectToOauthProviderPage />}>
                      <Route index element={null} />
                      <Route path=":provider/select-page-to-post">
                        <Route index element={<OnboardingSelectPageToPostPage />} />
                      </Route>
                    </Route>
                    <Route path=":provider">
                      <Route path="success" element={<OnboardingConnectToOauthSuccessPage />} />
                      <Route path="failure" element={<OnboardingConnectToOauthFailurePage />} />
                      <Route path="redirect" element={<ConnectToOauthRedirectPage />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route element={<WithOnboarding />}>
                <Route path="settings">
                  <Route element={<BrandSettingsPage />}>
                    <Route index element={null} />
                    <Route path="connect-to-oauth-provider/:provider/select-page-to-post">
                      <Route index element={<SettingsSelectPageToPostPage />} />
                    </Route>
                  </Route>
                  <Route path="connect-to-oauth-provider">
                    <Route path="callback" element={<SettingsConnectToOauthCallbackPage />} />
                    <Route path=":provider">
                      <Route path="success" element={<SettingsConnectToOauthSuccessPage />} />
                      <Route path="failure" element={<SettingsConnectToOauthFailurePage />} />
                      <Route path="redirect" element={<ConnectToOauthRedirectPage />} />
                    </Route>
                  </Route>
                </Route>
                <Route element={<WithSubscription />}>
                  <Route
                    path="social-media-weekly-calendar"
                    element={<SocialMediaWeeklyCalendar />}
                  >
                    <Route
                      path="post-connection-to-oauth-provider"
                      element={<PostConnectionToOauthProviderPage />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="checkout">
            <Route path="callback" element={<CheckoutCallbackPage />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
