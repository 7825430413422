import { lazy } from "react";

export const BrandLayout = lazy(() => import("pages/brands/[brandId]/brandLayout"));
export const RootPage = lazy(() => import("pages/rootPage"));
export const OnboardingSelectPageToPostPage = lazy(
  () =>
    import(
      "pages/brands/[brandId]/connect-to-oauth-provider/[provider]/select-page-to-post/selectPageToPostPage"
    )
);
export const LoginCallbackPage = lazy(() => import("pages/auth/loginCallback/loginCallbackPage"));
export const LoginPage = lazy(() => import("pages/auth/login/loginPage"));
export const LogoutPage = lazy(() => import("pages/auth/logout/logoutPage"));
export const BrandSettingsPage = lazy(
  () => import("pages/brands/[brandId]/settings/brandSettingsPage")
);
export const ConnectToOauthProviderPage = lazy(
  () => import("pages/brands/[brandId]/connect-to-oauth-provider/connectToOauthProviderPage")
);
export const ChangeBrandPage = lazy(() => import("pages/brands/[brandId]/change/changeBrandPage"));
export const BrandMiniScrapePage = lazy(
  () => import("pages/brands/[brandId]/mini-scraper/brandMiniScrapePage")
);
export const BrandPage = lazy(() => import("pages/brands/[brandId]/brandPage"));
export const BrandsPage = lazy(() => import("pages/brands/brandsPage"));
export const LastVisitedBrandPage = lazy(
  () => import("pages/brands/last-visited/lastVisitedBrandPage")
);
export const LatestBrandPage = lazy(() => import("pages/brands/latest/latestBrandPage"));
export const CreateBrandPage = lazy(() => import("pages/brands/create/createBrandPage"));
export const ConnectToOauthProviderLayout = lazy(
  () => import("pages/brands/[brandId]/connect-to-oauth-provider/connectToOauthProviderLayout")
);
export const SocialMediaWeeklyCalendar = lazy(
  () => import("pages/brands/[brandId]/weekly-calendar/SocialMediaWeeklyCalendar")
);
export const OnboardingConnectToOauthCallbackPage = lazy(
  () =>
    import("pages/brands/[brandId]/connect-to-oauth-provider/callback/connectToOauthCallbackPage")
);
export const OnboardingConnectToOauthSuccessPage = lazy(
  () =>
    import(
      "pages/brands/[brandId]/connect-to-oauth-provider/[provider]/success/connectToOauthSuccessPage"
    )
);
export const OnboardingConnectToOauthFailurePage = lazy(
  () =>
    import(
      "pages/brands/[brandId]/connect-to-oauth-provider/[provider]/failure/connectToOauthFailurePage"
    )
);
export const SettingsConnectToOauthCallbackPage = lazy(
  () =>
    import(
      "pages/brands/[brandId]/settings/connect-to-oauth-provider/callback/connectToOauthCallbackPage"
    )
);
export const SettingsConnectToOauthSuccessPage = lazy(
  () =>
    import(
      "pages/brands/[brandId]/settings/connect-to-oauth-provider/[provider]/success/connectToOauthSuccessPage"
    )
);
export const SettingsSelectPageToPostPage = lazy(
  () =>
    import(
      "pages/brands/[brandId]/settings/connect-to-oauth-provider/[provider]/select-page-to-post/selectPageToPostPage"
    )
);
export const SettingsConnectToOauthFailurePage = lazy(
  () =>
    import(
      "pages/brands/[brandId]/settings/connect-to-oauth-provider/[provider]/failure/connectToOauthFailurePage"
    )
);
export const PostConnectionToOauthProviderPage = lazy(
  () =>
    import(
      "pages/brands/[brandId]/weekly-calendar/post-connection-to-oauth-provider/PostConnectionToOauthProviderPage"
    )
);
export const NewUserPage = lazy(() => import("pages/new-user/newUserPage"));
export const BrandNotFoundPage = lazy(() => import("pages/brands/not-found/brandNotFoundPage"));
export const SelectBrandPage = lazy(() => import("pages/brands/select/selectBrandPage"));
export const AutopilotPage = lazy(() => import("pages/brands/[brandId]/autopilot/autopilotPage"));
export const CheckoutCallbackPage = lazy(
  () => import("pages/checkout/callback/CheckoutCallbackPage")
);
export const ConnectToOauthRedirectPage = lazy(
  () =>
    import(
      "pages/brands/[brandId]/connect-to-oauth-provider/[provider]/redirect/connectToOauthRedirectPage"
    )
);
export const OnboardingLayout = lazy(() => import("components/OnboardingLayout/OnboardingLayout"));
