import { useGetSubscriptionDetailsQuery } from "api/services/subscription/subscription.service";
import { Outlet, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux.hooks";
import { useEffect } from "react";
import { UserNotification } from "state/features/userNotifications/userNotificationsSlice";
import {
  removeUserNotification,
  upsertUserNotification,
} from "state/features/userNotifications/userNotificationsActions";

export const WithSubscription = () => {
  const { brandId } = useParams<{ brandId: string }>();
  const dispatch = useAppDispatch();
  const userSub = useAppSelector((state) => state.auth.sub);
  if (!userSub) {
    throw new Error("User sub is required");
  }
  if (!brandId) {
    throw new Error("BrandPage id is required");
  }
  const { currentData: subscriptionDetails } = useGetSubscriptionDetailsQuery(brandId);

  useEffect(() => {
    //UPDATE PERSISTED PLAN EXPIRED NOTIFICATION
    if (subscriptionDetails === undefined) return;
    if (subscriptionDetails === false) {
      dispatch(upsertUserNotification({ userSub, notification: UserNotification.PLAN_EXPIRED }));
    }
    if (subscriptionDetails === true) {
      dispatch(removeUserNotification({ userSub, notification: UserNotification.PLAN_EXPIRED }));
    }
  }, [brandId, dispatch, subscriptionDetails, userSub]);

  return <Outlet />;
};
