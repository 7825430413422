import { useMemo } from "react";
import { IntegrationProvider } from "types/integrationProviders.type";
import { useGetBrandIntegrationsQuery } from "api/services/integrations/integrations.service";

interface UseOnboardingProps {
  skip?: boolean;
  brandId: string;
}

export function useOnboarding({ skip, brandId }: UseOnboardingProps) {
  if (!brandId && !skip) {
    throw new Error("Missing brandId in WithIntegrations");
  }

  const integrationsQuery = useGetBrandIntegrationsQuery(
    { brandId: brandId! },
    { skip: !brandId || skip }
  );

  const brandHasFullyConnectedIntegrations = useMemo(() => {
    if (skip) {
      return undefined;
    }
    if (integrationsQuery.isSuccess) {
      const connectedProviders = Object.entries(integrationsQuery.data?.integrations ?? {}).filter(
        ([, connected]) => connected
      );
      return connectedProviders.some(([provider]) => {
        const numberOfPages = Object.keys(
          integrationsQuery.data?.pages?.[provider as IntegrationProvider] ?? {}
        ).length;
        return numberOfPages > 0;
      });
    }
    return undefined;
  }, [
    integrationsQuery.data?.integrations,
    integrationsQuery.data?.pages,
    integrationsQuery.isSuccess,
    skip,
  ]);

  return { brandHasFullyConnectedIntegrations };
}
