import { IconKey } from "assets/IconList";

export enum SupportedLocale {
  en = "en",
  es = "es",
  de = "de",
  hi = "hi",
  it = "it",
  he = "he",
  fr = "fr",
}

export const localeLabel: Record<SupportedLocale, string> = {
  en: "English",
  es: "Español",
  de: "Deutsch",
  hi: "हिन्दी",
  it: "Italiano",
  he: "עברית",
  fr: "Français",
};

export const localeIcon: Record<SupportedLocale, IconKey> = {
  en: "Usa",
  es: "Spain",
  de: "Germany",
  hi: "India",
  it: "Italy",
  he: "Israel",
  fr: "France",
};

export const suppportedPostingLocale = [SupportedLocale.en, SupportedLocale.es, SupportedLocale.he];
