import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "utils/dynamicBaseQuery";
import {
  trackOauthConnectEnd,
  trackOauthConnectRedirect,
  trackOauthConnectStart,
  trackOauthProviderPostingPageSelectionEnd,
  trackOauthProviderPostingPageSelectionStart,
  trackPostingPagesListEnd,
  trackPostingPagesListStart,
} from "state/features/analytics/analyticsActions";
import { IntegrationProvider } from "types/integrationProviders.type";
import { SocialMediaPage } from "types/socialMediaPage.type";
import { BrandOauthData } from "types/brandOauthData.type";

const integrationsService = createApi({
  reducerPath: "integrationsService",
  baseQuery: dynamicBaseQuery,
  tagTypes: ["brandOauthData"],
  endpoints: (builder) => ({
    connectToOAuthProvider: builder.query<
      { integrationRedirectUrl: string },
      { clientRedirectUrl: string; brandId: string; provider: IntegrationProvider }
    >({
      queryFn: async ({ brandId, clientRedirectUrl, provider }, api, _arg, fetchWithBQ) => {
        try {
          const { data } = (await fetchWithBQ({
            url: `brand/${brandId}/oauth/${provider}`,
            method: "POST",
            params: { redirect_to: clientRedirectUrl },
            retryOptions: {
              maxRetries: 2,
            },
          })) as { data: { redirect_url: string } };
          const integrationRedirectUrl = data["redirect_url"];
          if (!integrationRedirectUrl) {
            return { error: { data: "Missing redirect url", status: 500 } };
          }
          return {
            data: {
              integrationRedirectUrl,
            },
          };
        } catch (error) {
          return { error: { data: "Failed to create brand", status: 500 } };
        }
      },
      onQueryStarted: async ({ brandId, provider }, { dispatch, queryFulfilled }) => {
        dispatch(
          trackOauthConnectStart({
            brandId: brandId!,
            provider: provider!,
          })
        );
        try {
          await queryFulfilled;
          dispatch(
            trackOauthConnectRedirect({
              brandId: brandId!,
              provider: provider!,
            })
          );
        } catch (e) {
          dispatch(
            trackOauthConnectEnd({
              status: "rejected",
              brandId: brandId!,
              provider: provider!,
            })
          );
          return;
        }
      },
    }),
    getBrandIntegrations: builder.query<BrandOauthData, { brandId: string }>({
      queryFn: async ({ brandId }, api, _arg, fetchWithBQ) => {
        try {
          const { data } = (await fetchWithBQ({
            url: `brand/${brandId}/oauth`,
            method: "GET",
          })) as { data: BrandOauthData };
          const integrations = data["integrations"];
          if (!integrations) {
            return { error: { data: "Missing connect-to-oauth-provider-provider", status: 500 } };
          }
          return {
            data,
          };
        } catch (error) {
          return {
            error: { data: "Failed to get connect-to-oauth-provider-provider", status: 500 },
          };
        }
      },
      providesTags: ["brandOauthData"],
    }),
    getPages: builder.query<
      { [pageId: string]: SocialMediaPage },
      { brandId: string; provider: IntegrationProvider }
    >({
      queryFn: async ({ brandId, provider }, api, _arg, fetchWithBQ) => {
        async function fetchPages() {
          const { data } = (await fetchWithBQ({
            url: `brand/${brandId}/oauth/${provider}/pages`,
            method: "GET",
          })) as { data: { pages: { [pageId: string]: SocialMediaPage } } };
          const pages = data["pages"];
          if (!("pages" in data)) {
            return Promise.reject({ error: { data: "Missing Pages", status: 500 } });
          }
          return pages;
        }
        async function freeze(ms: number) {
          return new Promise((resolve) => setTimeout(resolve, ms));
        }
        try {
          const pages = await fetchPages();
          return {
            data: {
              ...pages,
            },
          };
        } catch (error) {
          //accept the error and try again
          console.error("Failed to get Pages on first attempt", error);
        }
        await freeze(1000);
        //second attempt
        try {
          const pages = await fetchPages();
          return {
            data: {
              ...pages,
            },
          };
        } catch (error) {
          //accept the error and try again
          console.error("Failed to get Pages on second attempt", error);
        }
        await freeze(2000);
        //third attempt
        try {
          const pages = await fetchPages();
          return {
            data: {
              ...pages,
            },
          };
        } catch (error) {
          return { error: { data: "Failed to get Pages", status: 500 } };
        }
      },
      providesTags: ["brandOauthData"],
      onQueryStarted: async ({ brandId, provider }, { dispatch, queryFulfilled }) => {
        let status: "resolved" | "rejected" = "resolved";
        try {
          await queryFulfilled;
          dispatch(
            trackPostingPagesListStart({
              brandId: brandId!,
              provider: provider!,
            })
          );
        } catch (e) {
          status = "rejected";
        }
        dispatch(
          trackPostingPagesListEnd({
            status,
            brandId: brandId!,
            provider: provider!,
          })
        );
      },
    }),
    setPostingPage: builder.mutation<
      { success: boolean },
      { brandId: string; provider: IntegrationProvider; pageId: string; pageName: string }
    >({
      queryFn: async ({ brandId, provider, pageId, pageName }, api, _arg, fetchWithBQ) => {
        try {
          const { data } = (await fetchWithBQ({
            url: `brand/${brandId}/oauth/${provider}/pages`,
            method: "POST",
            body: {
              page: {
                id: pageId,
                name: pageName,
              },
            },
          })) as { data: { success: boolean } };
          const success = data["success"];
          if (!success) {
            return { error: { data: "Failed to set Posting Page", status: 500 } };
          }
          return {
            data: {
              success,
            },
          };
        } catch (error) {
          return { error: { data: "Failed to set Posting Page", status: 500 } };
        }
      },
      invalidatesTags: ["brandOauthData"],
      onQueryStarted: async ({ brandId, provider }, { dispatch, queryFulfilled }) => {
        dispatch(
          trackOauthProviderPostingPageSelectionStart({
            brandId: brandId!,
            provider: provider!,
          })
        );
        try {
          await queryFulfilled;
          dispatch(
            trackOauthProviderPostingPageSelectionEnd({
              status: "resolved",
              brandId: brandId!,
              provider: provider!,
            })
          );
        } catch (e) {
          dispatch(
            trackOauthProviderPostingPageSelectionEnd({
              status: "rejected",
              brandId: brandId!,
              provider: provider!,
            })
          );
          return;
        }
      },
    }),
  }),
});
export const {
  useSetPostingPageMutation,
  useConnectToOAuthProviderQuery,
  useGetBrandIntegrationsQuery,
  useGetPagesQuery,
} = integrationsService;

export { integrationsService };
