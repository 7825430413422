import userNotificationsSlice, {
  UserNotification,
} from "state/features/userNotifications/userNotificationsSlice";
import { createAction } from "@reduxjs/toolkit";

export const { setUserNotifications } = userNotificationsSlice.actions;

export const removeUserNotification = createAction<{
  userSub: string;
  notification: UserNotification;
}>("userNotifications/removeUserNotification");

export const upsertUserNotification = createAction<{
  userSub: string;
  notification: UserNotification;
}>("userNotifications/upsertUserNotification");
