import { createSlice } from "@reduxjs/toolkit";

export enum BrandNotification {
  POSTS_IN_MAKING = "posts_in_making",
  POSTS_ARE_READY = "posts_are_ready",
}
export interface BrandNotificationsState {
  [brandId: string]: BrandNotification[];
}

const initialState: BrandNotificationsState = {};

const brandNotificationsSlice = createSlice({
  initialState,
  name: "brandBlockers",
  reducers: {
    setBrandNotification: (
      state,
      action: {
        payload: {
          brandId: string;
          notifications: BrandNotification[];
        };
      }
    ) => {
      const { brandId, notifications } = action.payload;
      state[brandId] = notifications;
    },
  },
});

export default brandNotificationsSlice;
