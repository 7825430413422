import { Middleware } from "@reduxjs/toolkit";
import {
  requestLogout,
  resetAuth,
  setAuth,
  setEmail,
  setExpiresAt,
  setIdToken,
  setIsAdmin,
  setName,
  setOrganizationId,
  setSub,
  setWorkspaceDomain,
} from "state/features/auth/authActions";
import { authService } from "api/services/auth/authService";
import { AppDispatch } from "types/state.type";
import { resetUser } from "state/features/analytics/analyticsActions";

const setAuthSplit: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (setAuth.match(action)) {
      const { idToken, organizationId, sub, email, name, workspaceDomain, expiresAt, isAdmin } =
        action.payload;
      if (idToken) {
        dispatch(setIdToken(idToken));
      }
      if (organizationId) {
        dispatch(setOrganizationId(organizationId));
      }
      if (sub) {
        dispatch(setSub(sub));
      }
      if (email) {
        dispatch(setEmail(email));
      }
      if (name) {
        dispatch(setName(name));
      }
      if (workspaceDomain) {
        dispatch(setWorkspaceDomain(workspaceDomain));
      }
      if (expiresAt) {
        dispatch(setExpiresAt(expiresAt));
      }
      if (isAdmin) {
        dispatch(setIsAdmin(isAdmin));
      }
    }
  };

const resetAuthSplit: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (resetAuth.match(action)) {
      dispatch(setIdToken(undefined));
      dispatch(setOrganizationId(undefined));
      dispatch(setSub(undefined));
      dispatch(setEmail(undefined));
      dispatch(setName(undefined));
      dispatch(setWorkspaceDomain(undefined));
      dispatch(setExpiresAt(undefined));
      dispatch(setIsAdmin(undefined));
    }
  };

const requestLogoutEnrich: Middleware =
  ({ dispatch }: { dispatch: AppDispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (requestLogout.match(action)) {
      try {
        await dispatch(authService.endpoints?.logout.initiate());
        dispatch(resetUser());
        dispatch(resetAuth());
      } catch (e) {
        console.error(e);
      }
    }
  };

export const authMiddleware = [setAuthSplit, resetAuthSplit, requestLogoutEnrich];
