import authSlice, { AuthState } from "./authSlice";
import { createAction } from "@reduxjs/toolkit";

export const {
  setIdToken,
  setOrganizationId,
  setSub,
  setName,
  setWorkspaceDomain,
  setEmail,
  setExpiresAt,
  setIsAdmin,
  setRedirectToPage,
} = authSlice.actions;
export const setAuth = createAction<AuthState, "auth/setAuth">("auth/setAuth");
export const resetAuth = createAction<undefined, "auth/resetAuth">("auth/resetAuth");
export const oauthCallbackReceived = createAction<string, "auth/oauthCallbackReceived">(
  "auth/oauthCallbackReceived"
);
export const requestLogout = createAction<undefined, "auth/logout">("auth/logout");
