import { Brand } from "types/brand.type";
import { replaceAllObjKeys } from "utils/trasnformResponseFromApi/replaceAllObjKeys";

const transformBrandKeys: Record<string, string> = {
  brand_id: "brandId",
  brand_name: "brandName",
  created_at: "createdAt",
  updated_at: "updatedAt",
  organization_id: "organizationId",
  brand_settings: "brandSettings",
  social_media_autopilot: "socialMediaAutopilot",
  posting_tz: "postingTz",
  aggregated_answers: "aggregatedAnswers",
  image_url: "imageUrl",
  original_url: "originalUrl",
  social_links: "socialLinks",
  icon_url: "iconUrl",
  detected_language: "detectedLanguage",
};

function fromResponse(response: Record<keyof typeof transformBrandKeys, unknown>): Brand {
  return replaceAllObjKeys<Brand, Record<keyof typeof transformBrandKeys, unknown>>(
    response,
    (key) => {
      const newKey = transformBrandKeys[key];
      return newKey || key;
    }
  );
}

function toResponse(brand: Partial<Brand>): Record<string, unknown> {
  return replaceAllObjKeys<Record<string, unknown>, Partial<Brand>>(brand, (key) => {
    const newKey = Object.keys(transformBrandKeys).find((k) => transformBrandKeys[k] === key);
    return newKey || key;
  });
}

export default { fromResponse, toResponse };
