import configSlice from "state/features/config/configSlice";
import { createAction } from "@reduxjs/toolkit";

export const {
  setConfigCognitoClientId,
  setConfigCognitoUserPoolId,
  setConfigStatus,
  setConfigLoginUrl,
  setConfigApiBaseUrl,
  setConfigAdminOrganizationId,
  setConfigMixpanelToken,
  setConfigEnvironment,
  setConfigCbSite,
  setConfigPlansProps,
  setConfigStaticAssetsUrl,
  setConfigHomepageUrl,
  setConfigFacebookTesterOrganizationId,
  setConfigChargebeeSelfServePortalUrl,
  setConfigChargebeeCheckoutUrl,
  setConfigChargebeeCheckoutOrigin,
  setConfigChaosEnabled,
  setConfigChaosUnlikelihood,
} = configSlice.actions;

export const resetConfig = createAction<undefined, "config/resetConfig">("config/resetConfig");
export const refreshConfig = createAction<undefined, "config/refreshConfig">(
  "config/refreshConfig"
);
export const initializeConfig = createAction<undefined, "config/initializeConfig">(
  "config/initializeConfig"
);
