import { useEffect, useRef, useState } from "react";
import { Loader } from "components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "hooks/redux.hooks";
import {
  trackStaticAssetsFontsInitializeEnd,
  trackStaticAssetsFontsInitializeStart,
} from "state/features/analytics/analyticsActions";

export const WithFonts = ({ children }: { children: React.ReactNode }) => {
  const [loadingFonts, setLoadingFonts] = useState(true);
  const fontsLoaded = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const staticAssetsUrl = useAppSelector((state) => state.config.staticAssetsUrl);

  useEffect(() => {
    if (fontsLoaded.current) return;
    fontsLoaded.current = true;
    const fontByWeight = {
      300: `${staticAssetsUrl}/font/Rubik-Light.ttf`,
      400: `${staticAssetsUrl}/font/Rubik-Regular.ttf`,
      500: `${staticAssetsUrl}/font/Rubik-Medium.ttf`,
      600: `${staticAssetsUrl}/font/Rubik-SemiBold.ttf`,
      700: `${staticAssetsUrl}/font/Rubik-Bold.ttf`,
      800: `${staticAssetsUrl}/font/Rubik-ExtraBold.ttf`,
    };
    const promises = Object.entries(fontByWeight).map(([weight, url]) => {
      const font = new FontFace("Rubik", `url(${url})`, { weight });
      return font.load();
    });
    dispatch(trackStaticAssetsFontsInitializeStart());
    Promise.all(promises)
      .then((loadedFonts) => {
        loadedFonts.forEach((loadedFont) => {
          document.fonts.add(loadedFont);
        });
        setLoadingFonts(false);
        dispatch(
          trackStaticAssetsFontsInitializeEnd({
            status: "resolved",
          })
        );
      })
      .catch((error) => {
        console.error("Failed to load fonts", error);
        setLoadingFonts(false);
        dispatch(
          trackStaticAssetsFontsInitializeEnd({
            status: "rejected",
            error: JSON.stringify(error),
          })
        );
      });
  }, [dispatch, staticAssetsUrl]);

  if (loadingFonts)
    return (
      <div className="h-screen w-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  return children;
};
