import { useCallback, useEffect } from "react";
import i18n from "i18next";
import { useAppDispatch } from "hooks/redux.hooks";
import { trackStaticAssetsLocaleNamespaceLoadingFailed } from "state/features/analytics/analyticsActions";

export const WithLocale = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();

  const onFailedLoading = useCallback(
    (lng: string, ns: string, msg: string) => {
      console.error(`Failed to load i18n resources for ${lng}:${ns}`, msg);
      dispatch(
        trackStaticAssetsLocaleNamespaceLoadingFailed({ namespace: ns, language: lng, error: msg })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    i18n.on("loaded", async () => {
      if (!i18n.isInitialized) return;
      if (!i18n.hasLoadedNamespace("meta")) return;
      const language = i18n.t("meta:locale");
      if (language === i18n.language) return;
      await i18n.changeLanguage(language);
    });

    i18n.on("failedLoading", onFailedLoading);

    i18n.on("languageChanged", () => {
      document.body.dir = i18n.dir();
    });
  }, [onFailedLoading]);

  return children;
};
